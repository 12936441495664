import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/Button';
import ModalConfirmation from '../../components/ModalConfirmation';
import RadioButtonInput from '../../components/RadioButtonInput';
import { useStyles } from './style';

interface Props {
	convenios: any;
	onNextPage: () => void;
	setTypeSelected: (data: any) => void;
}

const SelectTypes = ({ convenios, onNextPage, setTypeSelected }: Props) => {
	const [creditOptions, setcreditOptions] = useState([] as any);
	const [optionSelected, setOptionSelected] = useState('');
	const [modalregresar, setModalregresar] = useState(false);
	const navigate = useNavigate();

	const onChangeRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setOptionSelected(value);
	};

	useEffect(() => {
		if (convenios) {
			ConveniosVisibles(convenios);
		}
	}, [convenios]);

	const ConveniosVisibles = (values: any) => {
		setcreditOptions([]);
		let valueTemp: any[] = [];
		if (values.filter((e: any) => e.producto === 'STM').length > 0) {
			valueTemp.push({
				label: 'Solución a Tu Medida (STM)',
				value: 'STM',
			});
		}
		if (values.filter((e: any) => e.producto === 'DCP').length > 0) {
			valueTemp.push({
				label: 'Dictamen de Capacidad de Pago Privado (DCP)',
				value: 'DCP',
			});
		}
		if (values.filter((e: any) => e.producto === 'BCN').length > 0) {
			valueTemp.push({
				label: 'Borrón y Cuenta Nueva (BCN)',
				value: 'BCN',
			});
		}
		if (values.filter((e: any) => e.producto === 'BCP').length > 0) {
			valueTemp.push({
				label: 'Borrón y Cuenta Nueva por Convenio Privado (BCP)',
				value: 'BCP',
			});
		}
		setcreditOptions(valueTemp);
	};

	const style = useStyles();
	return (
		<>
			<p className={style.bold_Center}>
				Consulta las reestructuras disponibles y selecciona la que más te convenga
			</p>
			<Box className={style.BoxRadioButton}>
				<RadioButtonInput
					value={optionSelected}
					name="Type-Restructuring"
					id="Restructuring-Request-Type"
					options={creditOptions}
					onChange={onChangeRadioButton}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}
			>
				<Box
					className={style.Box_Buttons}
					sx={{
						paddingTop: '30px',
						paddingBottom: '50px',
					}}
				>
					<Box className={style.BoxButton}>
						<CustomButton
							onClick={() => {
								setModalregresar(!modalregresar);
							}}
							variant="outlined"
							label="Regresar"
							styles={{ height: '100%' }}
						/>
					</Box>
					<Box className={style.BoxButton}>
						<CustomButton
							onClick={() => {
								setTypeSelected(optionSelected)
								onNextPage()
							}}
							variant="solid"
							label="Continuar"
							disabled={optionSelected.length !== 0 ? false : true}
						/>
					</Box>
				</Box>
			</Box>

			<ModalConfirmation
				open={modalregresar}
				confirmLabel="Finalizar"
				onConfirm={() => {
					setModalregresar(!modalregresar);
					navigate('/inicio');
				}}
				width="sm"
			>
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<p className={style.bold_Center_Blue}>
							No aceptaste ninguna reestructura
						</p>
						<p className={style.textAlign_Bold}>
							Gracias por utilizar los servicios digitales del Infonavit
						</p>
					</Box>
				</>
			</ModalConfirmation>
		</>
	);
};

export default SelectTypes;
