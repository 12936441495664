/* eslint-disable react-hooks/exhaustive-deps */
// DEPENDENCIES
import { useEffect, useState, ChangeEvent } from 'react';
import { Box, Divider, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
// COMPONENTS
import ModalLoading from '../../../../components/ModalLoading';
import CustomButton from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';
import CustomAlert from '../../../../components/CustomAlert';
// RESOURCES
import { address_base64, defaultAddressForm, IAlert } from '../utils';
import { isNumber } from '../../../../utils/expressions';
import { INTERNAL_MESSAGES } from '../../../../config/messageCatalog';
import { encrypt } from '../../../../utils/encrypt';
import { useStyles } from '../styles';
// ASSETS
import { contactRequestAPI, profileAPI } from '../../../../api/modules/profile';
import { RootState } from '../../../../store';
import { useNavigate } from 'react-router-dom';
import { useBitacora } from '../../../../hooks/useBitacora';
import { CONTACT_ACTIONS } from '../../../../config/actions-bitacora/Contact-actions';

const ContactAddress = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const navigate = useNavigate();
	const [address, setAddress] = useState(defaultAddressForm);
	const [errors, setErrors] = useState(defaultAddressForm);
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState<IAlert>({ message: '', color: 'error' });
	const [stateOptions, setStateOptions] = useState<any>([]);
	const [delegacionOptions, setDelegacionOptions] = useState<any>([]);
	const [coloniaOptions, setColoniaOptions] = useState<any>([]);
	const { setDataOld, regBitacora } = useBitacora();

	const fetchAddressData = async () => {
		try {
			setLoader(true);
			const USER_NSS_BASE64 = await encrypt(
				user?.nss?.toString() || '',
				user?.k || ''
			);
			const resFetchAddress = await profileAPI.fetchAddressInfo({
				nss: USER_NSS_BASE64,
			});
			if (resFetchAddress?.result) {
				let fetchData: any = {};
				const ZIPCODE_BASE64 = await encrypt(
					resFetchAddress?.result?.cp || ' ',
					user?.k || ''
				);
				const resFetchCatalog = await profileAPI.fetchAddressCatalog({
					cp: ZIPCODE_BASE64,
				});
				if (Array.isArray(resFetchCatalog?.result)) {
					setStateOptions([
						{
							value: resFetchCatalog?.result[0]
								?.entidadFederativa,
							text: resFetchCatalog?.result[0]
								?.denominacionSociedad,
						},
					]);
					fetchData = {
						...resFetchAddress?.result,
						state: resFetchCatalog?.result[0]?.entidadFederativa,
					};
				} else {
					setStateOptions([
						{
							value: resFetchCatalog?.result?.entidadFederativa,
							text: resFetchCatalog?.result?.denominacionSociedad,
						},
					]);
					fetchData = {
						...resFetchAddress?.result,
						state: resFetchCatalog?.result?.entidadFederativa,
						delegacion: resFetchCatalog?.result?.estadoMunicipio,
					};
					setDelegacionOptions([
						{
							value: resFetchCatalog?.result?.estadoMunicipio,
							text: resFetchCatalog?.result?.delegacion,
						},
					]);
					const _Colonias = resFetchCatalog?.result?.colonias.map(
						(value: any) => {
							return {
								value: value,
								text: value,
							};
						}
					);
					setColoniaOptions(_Colonias);
				}
				setAddress(fetchData);
				setDataOld(CONTACT_ACTIONS.UPDATE_ADRESS, fetchData);
				return;
			}
			setAlert({
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
		} catch (error: any) {
			setAlert({
				message: error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
				color: 'error',
			});
			setLoader(false);
		} finally {
			setLoader(false);
		}
	};

	const getAddressCatalog = async () => {
		if (address.cp.length === 5) {
			try {
				setLoader(true);
				const ZIPCODE_BASE64 = await encrypt(
					address.cp || ' ',
					user?.k || ''
				);
				const resFetchCatalog = await profileAPI.fetchAddressCatalog({
					cp: ZIPCODE_BASE64,
				});
				if (Array.isArray(resFetchCatalog?.result)) {
					setStateOptions([
						{
							value: resFetchCatalog?.result[0]
								?.entidadFederativa,
							text: resFetchCatalog?.result[0]
								?.denominacionSociedad,
						},
					]);
					setAddress({
						...address,
						state: resFetchCatalog?.result[0]?.entidadFederativa,
					});
				} else {
					setStateOptions([
						{
							value: resFetchCatalog?.result?.entidadFederativa,
							text: resFetchCatalog?.result?.denominacionSociedad,
						},
					]);
					setAddress({
						...address,
						state: resFetchCatalog?.result?.entidadFederativa,
						delegacion: resFetchCatalog?.result?.estadoMunicipio,
					});
					setDelegacionOptions([
						{
							value: resFetchCatalog?.result?.estadoMunicipio,
							text: resFetchCatalog?.result?.delegacion,
						},
					]);
					const _Colonias = resFetchCatalog?.result?.colonias.map(
						(value: any) => {
							return {
								value: value,
								text: value,
							};
						}
					);
					setColoniaOptions(_Colonias);
				}
			} catch (error) {
			} finally {
				setLoader(false);
			}
			return;
		}
		setAddress({ ...address, state: '', delegacion: '', colony: '' });
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setAddress({ ...address, [name]: value });
		if (!value) setErrors({ ...errors, [name]: 'Campo obligatorio' });
		else setErrors({ ...errors, [name]: '' });
	};

	const handleConfirm = async () => {
		try {
			setLoader(true);
			const dataOrigin = { ...address, nss: user?.nss?.toString() || '' };
			const data_base64 = await address_base64(dataOrigin, user?.k || '');
			contactRequestAPI.updateAddress
				.post<
					{
						nss: string;
						cp: string;
						state: string;
						delegacion: string;
						colony: string;
						street: string;
						noExterior: string;
						noInterior: string;
					},
					any
				>('', data_base64)
				.then((res: any) => {
					if (res?.ok) {
						setAlert({
							message: INTERNAL_MESSAGES.APPLICATION_COMPLETED,
							color: 'success',
						});
						regBitacora({
							key: CONTACT_ACTIONS.UPDATE_ADRESS,
							scheme: contactRequestAPI.updateAddress.scheme,
							valorNuevo: address,
						});
					}
				})
				.catch((e) => {
					setAlert({
						message: e || 'Error con su solicitud',
						color: 'error',
					});
					throw e;
				})
				.finally(() => setLoader(false));
		} catch (error: any) {}
	};

	const handleClose = () => {
		setAlert({ message: '', color: 'success' });
	};

	useEffect(() => {
		getAddressCatalog();
	}, [address.cp]);

	useEffect(() => {
		fetchAddressData();
	}, []);

	return (
		<>
			<ModalLoading loading={loader} />
			<Paper className={classes.paperContainer}>
				<Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
					<span className={classes.tituloAcordion}>
						Domicilio actual
					</span>
				</Box>
				<Divider />
				<div className={classes.mailformContainer}>
					<TextInput
						isRequired
						label="Código postal"
						id="cp"
						name="cp"
						value={address.cp}
						error={Boolean(errors.cp)}
						helperText={errors.cp}
						onChange={(event) =>
							isNumber(event.target.value) &&
							event.target.value.length <= 5 &&
							handleChange(event)
						}
					/>
					<SelectInput
						disabled
						isRequired
						id="state"
						name="state"
						label="Estado"
						value={address.state}
						helperText={errors.state}
						onChange={handleChange}
						options={stateOptions}
					/>
					<SelectInput
						disabled
						isRequired
						label="Delegación o municipio"
						id="delegacion"
						name="delegacion"
						value={address.delegacion}
						helperText={errors.delegacion}
						onChange={handleChange}
						options={delegacionOptions}
					/>
					<SelectInput
						isRequired
						label="Colonia"
						id="colony"
						name="colony"
						value={address.colony}
						helperText={errors.colony}
						onChange={handleChange}
						options={coloniaOptions}
					/>
					<TextInput
						isRequired
						label="Calle"
						id="street"
						name="street"
						value={address.street}
						error={Boolean(errors.street)}
						helperText={errors.street}
						onChange={handleChange}
					/>
					<TextInput
						isRequired
						label="Número exterior"
						id="noExterior"
						name="noExterior"
						value={address.noExterior}
						error={Boolean(errors.noExterior)}
						helperText={errors.noExterior}
						onChange={(event) =>
							isNumber(event.target.value) && handleChange(event)
						}
					/>
					<TextInput
						label="Número interior"
						id="noInterior"
						name="noInterior"
						value={address.noInterior}
						onChange={handleChange}
					/>
				</div>
				<CustomAlert
					show={Boolean(alert.message)}
					severity={alert.color}
					message={alert.message}
					onClose={handleClose}
				/>
				<div className={classes.actionsContainer}>
					<CustomButton
						label="Salir"
						disabled={false}
						variant="outlined"
						onClick={() => navigate('/')}
					/>
					<CustomButton
						label={'Guardar cambios'}
						disabled={
							!Boolean(
								address.cp &&
									address.state &&
									address.delegacion &&
									address.colony &&
									address.street &&
									address.noExterior
							) || Boolean(alert.message)
						}
						variant="solid"
						onClick={handleConfirm}
					/>
				</div>
				<div className={classes.containerRequiredFields}>
					<Divider />
					<p className={classes.requiredFieldsLabel}>
						* Datos obligatorios
					</p>
				</div>
			</Paper>
		</>
	);
};

export default ContactAddress;
