import API from '..';

class ApiSaldAndMovements extends API {
	getBalance(credit: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/balance`,
				method: 'POST',
				data: { credit },
			})
		);
	}
	getCredito(credit: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/datos-mi-credito`,
				method: 'POST',
				data: { credit },
			})
		);
	}
	getPeriodo(credit: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/periods`,
				method: 'POST',
				data: { credit },
			})
		);
	}
	getAnios(credit: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/anios`,
				method: 'POST',
				data: { credit },
			})
		);
	}
	getPapperlessStatus(credit: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/paperless/status`,
				method: 'POST',
				data: { credit },
			})
		);
	}
	setPapperlessStatus(credit: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/paperless`,
				method: 'POST',
				data: { credit },
			})
		);
	}
	getPdfHistorico(credit: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/genera-estado-cuenta-historico-pdf`,
				method: 'POST',
				data: { credit },
			})
		);
	}
	getPdfMensual(credit: string, period: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/genera-estado-cuenta-mensual-pdf`,
				method: 'POST',
				data: { credit, period },
			})
		);
	}
	getPdfPagoServ(credit: string, anio: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/genera-reporte-pagos-pdf`,
				method: 'POST',
				data: { credit, anio },
			})
		);
	}
	getMovimientos(period: string, credit: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/balance/credit-movements`,
				method: 'POST',
				data: { period, credit },
			})
		);
	}
	getPdfMovimientos(
		credit: string,
		desde: string,
		nombreAcreditado: string,
		moneda: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/balance/credit-movements-pdf`,
				method: 'POST',
				data: { credit, desde, nombreAcreditado, moneda },
			})
		);
	}
	paymentBBVA(
		nss: string,
		credito: string,
		monto: string,
		fullName: string,
		email: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `saldos-movimientos/operacion-bancomer`,
				method: 'POST',
				data: { nss, credito, monto, fullName, email },
			})
		);
	}
	paymentCodi(
		nss: string,
		credito: string,
		monto: string,
		fullName: string,
		email: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `saldos-movimientos/operacion-codi`,
				method: 'POST',
				data: { nss, credito, monto, fullName, email },
			})
		);
	}
	paymentCuenta(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/aportaciones-ext-pago-cuenta`,
				method: 'POST',
				data: data,
			})
		);
	}
	getPagosParciales(credit: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/salmov/pagos-parciales`,
				method: 'POST',
				data: { credit },
			})
		);
	}
	getCertificadoCalidad(
		credito: string
	): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/saldos-movimientos/certificado-calidad`,
				method: 'POST',
				data: { credito },
			})
		);
	}
}

export const apiSaldAndMovements = new ApiSaldAndMovements('');
