import React from 'react';

export interface IPersonInformation {
	nss: string;
	creditoVigente: boolean;
	creditosHIP: number;
	nrp: string;
	nombreCompleto: string;
	rfc: string;
	apellidoPaterno: string;
	apellidoMaterno: string;
	nombre: string;
	curp: string;
	sexo: string;
	estadoMunicipio: string;
	bimestreTope: string;
	valorUMA: string;
	salarioDiario: string;
	edad: string;
	saldoSAR92: string;
	saldoSAR97: string;
	saldoSARTotal: string;
	tipoTrabajador: string;
	fechaPreca: string;
	bimestresContinuos: string;
	bimestresContinuosMismoPatron: string;
	puntosEdad: string;
	puntosSaldoSAR: string;
	puntosBimestresContinuos: string;
	puntosTotales: string;
	puntosTipoTrabajador?: string;
	puntosPatronEstabilidad?: string;
	puntosPatronComportamiento?: string;
	puntosPatronContexto?: string;
	puntosPatronTotales?: string;
	puntosUsuarioTotales?: string;
	puntosEdadSalario?: string;
	puntosMinimos?: string;
	puntosMujer?: string;
	indiceRiesgo: string;
	nssUnificado: string;
	mesPara116Puntos: string;
	anioPara116Puntos: string;
	nombreMesPara116: string;
	puntosFaltantesPara116: string;
	esPolicia: boolean;
	esPrecaAnticipada: boolean;
	esSegundoCredito: boolean;
	sueldo: string;
	estatusAsociacion: string;
	code: string;
	tipoAsociacion: string;
	message: string;
	destinosAplicables: Array<{
		codigo: string;
		descripcion: string;
		estatus: boolean;
	}>;
	mevi: IMevi;
	esCrediterreno?: boolean;
}

export interface IMevi {
	codigo: string;
	mensaje: string;
	stageId: string | number;
	folio: string;
	email: string;
	creditoHipotecario: boolean;
	value?: string | number;
	from?: string;
}
export interface IGeneralInformation {
	codigo: string;
	mensaje: string;
	destinosAplicables: Array<{
		codigo: string;
		descripcion: string;
		estatus: boolean;
		validaciones: Array<string>;
	}>;
	codigoDestino: string;
	conyuge: IPersonInformation;
	titular: IPersonInformation;
	ubicacion: any;
	finishLoaded: boolean;
	agroFinalizado: string;
	agroBaja: string;
	puntosInsuficientes: boolean;
	estadoMunicipio: string;
	estadoMunicipioN: string;
	propietario: string;
	esMejoraDirecta?: boolean;
	tipoVivienda?: string;
	producto?: string;
	esAjusteTransversal?: boolean;
}
export interface IObtenerElementos {
	success: boolean;
	avance: number;
	numero_elementos: number;
	ubicacion?: number;
	message?: string;
	elementos: Array<{
		idElemento: number;
		idTipoElemento: number;
		nombre: string;
		descripcion: string;
		direccionUrl: string;
		estatus: number;
	}>;
}
export interface ITiposCredito {
	descripcion?: string;
	codigo: string;
	estatus?: boolean;
	badge?: React.ReactChild;
}

export interface IProductMonto {
	producto: string;
	mmcMon: string;
	gasMon: string;
	cdcMon: string;
	mmcMonTab: string;
	facRoaCre: string;
	facReaCre: string;
	facRoaTra: string;
	facReaTra: string;
	desRoa: string;
	desRea: string;
	ctaAdm: string;
	segDan: string;
	fpp: string;
	tasInt: string;
	intMor: string;
	tipTab: string;
	pzoAhoRiss: string;
	monAhoMax: string;
	monAhoMin: string;
	desEco: string;
	monEco: string;
	desTot: string;
	pzoCre: string;
	desCre: string;
	mmcMonCny: string;
	gasMonCny: string;
	cdcMonCny: string;
	mmcMonTabCny: string;
	facRoaCreCny: string;
	facReaCreCny: string;
	facRoaTraCny: string;
	facReaTraCny: string;
	desRoaCny: string;
	desReaCny: string;
	tasIntCny: string;
	intMorCny: string;
	ctaAdmCny: string;
	segDanCny: string;
	fppCny: string;
	desTotCny: string;
	pzoCreCny: string;
	desCreCny: string;
	tieneHipotecaVerde: string;
	tieneHogarATuMedida: string;
	tieneHipotecaConServicios: string;
	tieneSubsidioFederal: string;
	cat: string | null;
	nvoFPP: string;
	nvoFPPCny: string;
	nvoCotaAdmin: string;
	nvoCotaAdminCny: string;
	diffCubrir: string;
	diffCubrirCny: string;
	catCny: string | null;
	// AUTOPRODUCCION
	contarias: string;
	mtoCre: string;
	pago: string;
	plazo: string;
	plazoEstimado: string;
	valorTerreno: string;
	subtipoTrazabilidad?: string;
	saldoSubcuentaVivienda?: number;
	saldoSubcuentaViviendaCny?: number;
	montoETC?: number;
	montoETC80?: number;
	montoETC20?: number;
  //Variable de montos maximos
	montoCreditoSolicitado?: string;
	montoCreditoSolicitadoCny?: string;
	ahorro?: string;
	ahorroCny?: string;
}
export interface IProductosMontos {
	codigo: string;
	mensaje: string;
	destino: string;
	topVecVSM: string;
	topVivEco: string;
	reglaCre: string;
	pzoCre: string;
	contadorProductos: string;
	productos: IProductMonto[];
}
export interface IPersonGeneralData {
	nss: string;
	montonPensionAlimenticia?: string;
	estadoMunicipio?: string;
}
export interface IGeneralData {
	titular: IPersonGeneralData;
}
export interface IConsultarProductosMontosData {
	destino: string;
	tipoCredito: string;
	producto: string;
	moneda: string;
	indicador1erO2do: string;
	lineaCredito: string;
	estadoMunicipio: string;
	numPart: string;
	opcionViviendaPart1: string;
	enganchePart1: string;
	plazoPart1?: string;
	plazoPart2?: string;
	valorTerreno?: string;
	titular: IPersonInformation;
	conyuge?: IPersonInformation;
	montoAvaluo?: string;
	montoTitular?: string;
	montoConyuge?: string;
}
export interface ICalculadoraData {
	nss: string | undefined;
	salarioMinimoDF: number | string;
	programaEspecial: string;
	montoCredito: number | string;
	sarTotal: number | string;
	cargasFinancieras: number | string;
	descuento: number | string;
	descuentoSinSubsidio: number | string;
	montoCreditoTabla: number | string;
	sueldoTrabajador: number | string;
	factorROA: number | string;
	factorREA: number | string;
	factorROACredito: number | string;
	factorREACredito: number | string;
	seguroDeDanos: number | string;
	participacionInfonavitCII: number | string;
	fpp: number | string;
	valorOperacion: number | string;
	montoCreditoSolicitado: number | string;
	descuentoCreditoSolicitado: number | string;
	tipoCredito: string;
	esquema: string;
	montoCreditoCny: string | number;
	sarTotalCny: string | number;
	cargasFinancierasCny: string | number;
	descuentoSinSubsidioCny: string | number;
	participacionInfonavitCIICny: string | number;
	montoCreditoTablaCny: string | number;
	sueldoTrabajadorCny: string | number;
	factorROACny: string | number;
	factorREACny: string | number;
	factorROACreditoCny: string | number;
	factorREACreditoCny: string | number;
	seguroDeDanosCny: string | number;
	fppCny: string | number;
	descuentoCny: string | number;
	montoCreditoSolicitadoCny: string | number;
	descuentoCreditoSolicitadoCny: string | number;
	plazoPart1?: string;
	plazoPart2?: string;
	montoMaximoETC?: any;
	lineaCredito?: string;
	montoAvaluo?: number;
}
export interface ICalculadoraRes {
	codigo: string;
	mensaje: string;
	valorOperacion: number;
	valorOperacionAjustada: number;
	montoMaximoCredito: number;
	saldoSubcuentaVivienda: number;
	cargasFinancieras: number;
	capacidadCompra: number;
	diferenciasCargoTrabajador: number;
	descuento: number;
	descuentoSinSubsidio: number;
	montoMaximoCreditoCny: number;
	saldoSubcuentaViviendaCny: number;
	cargasFinancierasCny: number;
	capacidadCompraCny: number;
	diferenciasCargoTrabajadorCny: number;
	descuentoCny: number;
	descuentoSinSubsidioCny: number;
	nvoFPP: number;
	nvoFPPCny: number;
	nvoCotaAdmin: number;
	nvoCotaAdminCny: number;
	producto: string;
}
export interface ITrazabilidadCRM {
	codigo: string;
	mensaje: string;
	caso: string;
}
export interface IObtenerCatData {
	valorUMA: string;
	salarioDiario: string;
	producto: string;
	pagCre: string;
	monCre: string;
	monCfi: string;
	tasInt: string;
	ctaAdm: string;
	monEco: string;
	desEco: string;
	segDan: string;
	fpp: string;
	conyuge: boolean;
}
export interface IObtenerCatRes {
	codigo: string;
	claveProducto: string;
	mensaje: string;
	cat: string;
	catCny: string;
	plazo: string;
}
export interface ITablaAmortizacionData {
	nss: string;
	tipoCredito: string;
	producto: string;
	montoMaximoCredito: string;
	descuentoTrabajador: string;
	tasaInteres: string;
	salarioDiarioIntegrado: string;
	descuentoCredito: string;
	salarioMinimoDiario: string;
	fondoProteccionPagos: string;
	descuentoTrabajadorEcos: string;
	destino: string;
	cat: string | null;
	descuentoTrabajadorSegDan: string;
	montoMaximoEcos: number | string;
	conyuge: boolean;
}
export interface ITablaAmortizacionRes {
	path: string;
	data: string;
	codigo: string;
}

export const defaultPersonInformation: IPersonInformation = {
	nss: '',
	creditoVigente: false,
	creditosHIP: 0,
	nrp: '',
	nombreCompleto: '',
	rfc: '',
	apellidoPaterno: '',
	apellidoMaterno: '',
	nombre: '',
	curp: '',
	sexo: '',
	estadoMunicipio: '',
	bimestreTope: '',
	valorUMA: '',
	salarioDiario: '',
	edad: '',
	saldoSAR92: '',
	saldoSAR97: '',
	saldoSARTotal: '',
	tipoTrabajador: '',
	fechaPreca: '',
	bimestresContinuos: '',
	bimestresContinuosMismoPatron: '',
	puntosEdad: '',
	puntosSaldoSAR: '',
	puntosBimestresContinuos: '',
	puntosTotales: '',
	puntosMujer: '',
	indiceRiesgo: '',
	nssUnificado: '',
	mesPara116Puntos: '',
	anioPara116Puntos: '',
	nombreMesPara116: '',
	puntosFaltantesPara116: '',
	esPolicia: false,
	esPrecaAnticipada: false,
	esSegundoCredito: false,
	estatusAsociacion: '',
	code: '',
	tipoAsociacion: '',
	message: '',
	sueldo: '',
	destinosAplicables: [],
	mevi: {
		codigo: '',
		mensaje: '',
		stageId: '',
		folio: '',
		email: '',
		creditoHipotecario: false,
	},
};
export const defaultGeneralInformation: IGeneralInformation = {
	codigo: '',
	mensaje: '',
	destinosAplicables: [],
	codigoDestino: '',
	conyuge: defaultPersonInformation,
	titular: defaultPersonInformation,
	ubicacion: 1,
	finishLoaded: false,
	agroFinalizado: '',
	agroBaja: '',
	puntosInsuficientes: false,
	estadoMunicipio: '',
	estadoMunicipioN: '',
	propietario: '',
	esMejoraDirecta: false,
};

export const defaultElementsLocation: IObtenerElementos = {
	success: false,
	avance: 0,
	numero_elementos: 0,
	elementos: [],
};
export interface IListaElementos {
	nss: string;
	destino: string;
	ubicacion: any;
	numFiltros: string;
	idCurso: string;
	amortizacion: string;
	sueldo: string;
	buro: string;
	tipo: string;
}
export interface IActualizarElementos {
	nss: string;
	destino: string;
	ubicacion: any;
	numFiltros: string;
	idCurso: string;
	elementoVisto: number;
}
export interface IActualizarElementosRes {
	success: boolean;
	avance: number;
}
export interface IObtenerCertificado {
	nss: string;
	nombreCompleto: string;
}
export interface IObtenerCertificadoRes {
	codigo: string;
	mensaje: string;
	data: any;
	url: string;
}
export interface IConsultaAutoproduccion {
	nss: string;
	stageId: string | number;
	producto: string;
}
export interface IConsultarCaso {
	nss: string;
	claseOperacion: string;
	numeroOrden?: string;
	estatus?: string;
}
export interface IConsultarCasoRes {
	tipoCredito: string;
	descripcion: string;
	objectId: string;
	estatus: string;
	fecha: string;
	fechaEstatus: string;
}
export interface IConsultaAutoproduccionRes {
	codigo: string;
	mensaje: string;
	valorUMA: string;
	salarioDiario: string;
	salarioDiarioPuntos: string;
	estSol: string;
	saldoSAR92: string;
	saldoSAR97: string;
	saldoSARTotal: string;
	bimFdo: string;
	bimestreSAR: string;
	bimestreTope: string;
	edaSalPto: string;
	puntosSaldoSAR: string;
	puntosBimestreSAR: string;
	puntosTotales: string;
	indiceRiesgo: string;
	tasaInteres: string;
	numeroPlazos: string;
	gastos: string;
	cat: string;
	sicSec: string;
	porSicSec: string;
	productos: [IProductoAutoproduccion];
}
export interface IProductoAutoproduccion {
	plazo: string;
	monto: string;
	montoCalculado: string;
	pago: string;
	contarias: string;
	plazoEstimado: string;
	pagoCalculado: string | number | undefined;
	tasaInteres: string;
	valorUMA: string;
}

export interface IProductoMejora {
	modalidad: number;
	modalidadDescripcion: string;
	plazo: number;
	montoCredito: number;
	gastosOperacion: number;
	contarias: number;
	pagoMensual: number;
	tasaInteres: number;
	cat: number;
	producto?: string;
}

export interface IAgroindustryDatosGenerales {
	situacionRegistro: string;
	nss: string;
	ahorroMensual: string;
	saldoAhorro: string;
	numeroMesesContinuos: string;
	puntos: number;
	paramComponent: string;
}

export interface IResponseEntidades {
	idTipoRegimen: string;
	nrp: string;
	razonSocial: string;
}
export interface IDataconsultarAgroconsulta {
	nss: string;
}
export interface IDataconsultarAgroconsultaRes {
	codigo: string;
	mensaje: string;
	nombre: string;
	nrp: string;
	edad: string;
	salarioDiario: string;
	valorUMA: string;
	numEdoPat: string;
	producto: string;
	metaAhorro: string;
	ahorroMensual: string;
	fechaInscripcion: string;
	fechaInicioAhorro: string;
	fechaFinalAhorro: string;
	fechaCorte: string;
	situacionRegistro: string;
	fechaCierre: string;
	puntosTotalCorte: string;
	saldoAhorro: string;
	numeroMesesContinuos: string;
	contadorDepositos: string;
	pagos: IPagosAgroconsulta[];
}
export interface IDataconsultarAgroRegistroRes {
	rfc: string;
	curp: string;
	nombreCompleto: string;
	edad: string;
	salarioDiario: string;
	valorUMA: string;
	estado: string;
	tipoTrabajador: string;
	producto: string;
	metaAhorro: string;
	ahorroMensual: string;
	fechaInscripcion: string;
	fechaInicioAhorro: string;
	fechaFinalAhorro: string;
}
export interface IPagosAgroconsulta {
	referenciaPago: string;
	fechaPago: string;
	imApoExt: string;
}
export interface IConsultarSaldoRes {
	codigo: string;
	mensaje: string;
	fecha: string;
	rfc: string;
	curp: string;
	nombre: string;
	apellidoPaterno: string;
	apellidoMaterno: string;
	afore: string;
	saldoSAR92: string;
	saldoSAR97: string;
	saldoSARTotal: string;
}
export interface IConsultarHistorialRes {
	codigo: string;
	codRet: string;
	mensaje: string;
	message: string;
	stageId: string;
	estatus: string;
	folio: string;
	email: string;
	creditoHipotecario: string;
}
export interface IConsultarHistorialParams {
	nss: string;
	contacto: {
		fechaNacimiento: string;
		nombre: string;
		segundoNombre: string;
		apellidoPaterno: string;
		apellidoMaterno: string;
		rfc: string;
		curp: string;
		telefono: string;
		email: string;
		cp: string;
		estadoId: string;
		municipio: string;
		colonia: string;
		calle: string;
		numeroExterior: string;
		numeroInterior: string;
	};
	referencia: {
		tieneTDC: boolean;
		tieneHipotecario: boolean;
		tieneAuto: boolean;
		salarioDiario: string;
		ultimos4DigitosTDC: string;
		salarioMensual?: string;
	};
}

export interface IObtenerListaElementosRes {
	success: boolean;
	numero_elementos: number;
	avance: number;
	elementos: IListaElemento[];
}
export interface IObtenerElementosUbicacionRes {
	success: boolean;
	avance: number;
	numero_elementos: number;
	elementos: IElementoUbicacionRes[];
}
export interface IElementoUbicacionRes {
	idElemento: number;
	idTipoElemento: number;
	nombre: string;
	descripcion: string;
	direccionUrl: string;
	fechaCreacion: number;
	fechaCaducidad: number;
	estatus: number;
}

export interface IListaElemento {
	idElemento: number;
	idTipoElemento: number;
	nombre: string;
	descripcion: string;
	direccionUrl: string;
	fechaCreacion: number;
	fechaCaducidad: number;
	estatus: number;
	direccionUrlOrigin: string;
	nombreSearch: string;
}
export interface IUrlEncripted {
	miuc: string;
	tipo: string;
	aplicacion?: string;
	nss?: string;
	nssConyuge?: string;
	noCaso?: string;
	motivoCancelacion?: string;
	lada?: string;
	telefono?: string;
	correo?: string;
	tiempo?: string;
	destino?: string;
	tipoOperacion?: string;
	programaEspecial?: string;
	producto?: string;
	moneda?: string;
	tipoCredito?: string;
	origen?: string;
}
export interface ITraza {
	nss: string;
	curp: string;
	rfc: string;
	nrp: string;
	nombre: string;
	apellidoPaterno: string;
	apellidoMaterno: string;
	fecha: string;
	mensaje: string;
	subtipoTrazabilidad: string;
}

export interface IProductMontoMejoraTuHogar {
	plazo?: string;
	montoCredito?: string;
	gastosOperacion?: string;
	contarias?: string;
	pagoMensual?: string;
	factorPago?: string;
	cat?: string;
	tasa?: string;
	producto?: string;
	salarioDiario?: string;
	valorUMA?: string;
	montoCreComercio?: number;
	montoCreManoObra?: number;
	montoCreRegVivienda?: number;
	montoCreditoCopia?: string;
	pagoMensualCopia?: string;
}

export interface IMejoraTuHogarProducts {
	valorUMA: string;
	salarioDiario: string;
	productos: IProductMontoMejoraTuHogar[];
}

export const defaultMejoraTuHogarProducts = {
	valorUMA: '',
	salarioDiario: '',
	productos: [],
};

export interface IProductMejoraTuHogarVit {
	producto: string;
	productos: IProductMontoMejoraTuHogar[];
}

export const defaultProductMonto = {
	producto: '',
	mmcMon: '',
	gasMon: '',
	cdcMon: '',
	mmcMonTab: '',
	facRoaCre: '',
	facReaCre: '',
	facRoaTra: '',
	facReaTra: '',
	desRoa: '',
	desRea: '',
	ctaAdm: '',
	segDan: '',
	fpp: '',
	tasInt: '',
	intMor: '',
	tipTab: '',
	pzoAhoRiss: '',
	monAhoMax: '',
	monAhoMin: '',
	desEco: '',
	monEco: '',
	desTot: '',
	pzoCre: '',
	desCre: '',
	mmcMonCny: '',
	gasMonCny: '',
	cdcMonCny: '',
	mmcMonTabCny: '',
	facRoaCreCny: '',
	facReaCreCny: '',
	facRoaTraCny: '',
	facReaTraCny: '',
	desRoaCny: '',
	desReaCny: '',
	tasIntCny: '',
	intMorCny: '',
	ctaAdmCny: '',
	segDanCny: '',
	fppCny: '',
	desTotCny: '',
	pzoCreCny: '',
	desCreCny: '',
	tieneHipotecaVerde: '',
	tieneHogarATuMedida: '',
	tieneHipotecaConServicios: '',
	tieneSubsidioFederal: '',
	cat: '',
	nvoFPP: '',
	nvoFPPCny: '',
	nvoCotaAdmin: '',
	nvoCotaAdminCny: '',
	diffCubrir: '',
	diffCubrirCny: '',
	catCny: '',
	contarias: '',
	mtoCre: '',
	pago: '',
	plazo: '',
	plazoEstimado: '',
	valorTerreno: '',
};

export interface IEstatusCuestionario {
	code: string;
	description: string;
	fechaRegistro: string;
}

export interface IBusquedaCuestionario {
	code: string;
	description: string;
	cuestionario: {
		nss: string;
		nombreDH: string;
		paginas: any;
	};
}

export interface ICompletoCuestionario {
	code: string;
	description: string;
}
