import { useEffect, useState } from 'react';
import { WORKSHOP_VIEWS } from '../utils';
import FinancialEducation from './FinancialEducation';
import IHaveAcredit from './IHaveACredit';
import IWantACredit from './IWantACredit';
import MySubAccount from './MySubAccount';
import useTallerMas from '../hooks/useElements';
import ModalLoading from '../../../../../components/ModalLoading';
import CustomAlert from '../../../../../components/CustomAlert';
import { INTERNAL_MESSAGES } from '../../../../../config/messageCatalog';
import CardsTaller from './CardsTaller';

const VIEWS_LIST = {
	[WORKSHOP_VIEWS.FINANTIAL_EDUCATION.key]: <FinancialEducation />,
	[WORKSHOP_VIEWS.I_WANT_A_CREDIT.key]: <IWantACredit />,
	[WORKSHOP_VIEWS.I_HAVE_A_CREDIT.key]: <IHaveAcredit />,
	[WORKSHOP_VIEWS.MY_SUB_ACCOUNT.key]: <MySubAccount />,
};

const GetViews = () => {
	const {
		activeView,
		getElementsAll,
		setActiveView,
		setElementos,
		setFinishLearn,
	} = useTallerMas();
	const [loading, setLoading] = useState(false);
	const getAllElements = async () => {
		try {
			setLoading(true);
			const response = await getElementsAll();
			setElementos(response);
			if (
				response[WORKSHOP_VIEWS.FINANTIAL_EDUCATION.key]?.secciones
					.length > 0 &&
				response[WORKSHOP_VIEWS.FINANTIAL_EDUCATION.key]?.secciones[0]
					.elementos.length > 0
			) {
				const elementos =
					response[WORKSHOP_VIEWS.FINANTIAL_EDUCATION.key]
						.secciones[0].elementos;
				const returnViews = elementos.reduce((arr, curr) => {
					return curr.estatus === 1 ? arr + 1 : arr;
				}, 0);
				setFinishLearn(elementos.length === returnViews);
			}
		} catch (error) {
			setActiveView(WORKSHOP_VIEWS.NOTHING);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getAllElements();
	}, []);
	return (
		<>
			<ModalLoading loading={loading} />
			{activeView.key === WORKSHOP_VIEWS.NOTHING.key ? (
				<CustomAlert
					message={INTERNAL_MESSAGES.ERROR_MESSAGE}
					severity="warning"
					show
				/>
			) : (
				<>
					<CardsTaller />
					{VIEWS_LIST[activeView?.key]}
				</>
			)}
		</>
	);
};

export default GetViews;
