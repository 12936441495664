import { createContext, Dispatch, SetStateAction } from 'react';
import {
	defaultGeneralInformation,
	IGeneralInformation,
} from '../interfaces/creditRequest';

const GeneralInformationContext = createContext<
	[IGeneralInformation, Dispatch<SetStateAction<IGeneralInformation>>]
>([defaultGeneralInformation, () => {}]);

export default GeneralInformationContext;
