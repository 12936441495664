import { useState } from 'react';
import {
	Grid,
	IconButton,
	LinearProgress,
	Paper,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
	KeyboardArrowDown,
	KeyboardArrowUp,
	ZoomOutMap,
} from '@mui/icons-material';
import ReactPlayer from 'react-player';
import { useStyles } from '../styles';
import { RoundBtn } from '../../../pages/AssociateNssCredit/Styles';
import theme from '../../../config/theme';
import { useElementsLocation } from '../../../hooks/useElementsLocation';
import BaseModal from '../../Modal';
import { useGeneralMIUC } from '../../../hooks/useGeneralMIUC';
import { destinos } from './utils';
import SaberMasIcon from '../../../assets/img/saber-mas-para-decidir-mejor-2022.png';

const initialStyles = {
	// transform: 'translateY(215px)',
	// transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
	// bottom: 111,
	// left: 0,
	// width: '100%'
};

const MaximizeElement = ({
	open,
	setOpen,
	element,
	setSelectedElement,
}: any) => {
	return (
		<BaseModal
			width="lg"
			open={open}
			onClose={() => {
				setOpen(!open);
				setSelectedElement(0);
			}}
			showDialogTitle
		>
			{element && !element?.direccionUrl.includes('youtube') && (
				<iframe
					allowFullScreen
					id={element?.nombre}
					title={element?.nombre}
					src={element?.direccionUrl}
					width={'100%'}
					height={830}
				/>
			)}
		</BaseModal>
	);
};

const Footer = ({ actualizarElementosUbicacion }: any) => {
	const generalInfo = useGeneralMIUC()[0];
	const elementsLocation = useElementsLocation()[0];
	const [open, setOpen] = useState(false);
	const [selectedElement, setSelectedElement] = useState<number>(0);
	const [footerStyles, setFooterStyles] = useState<any>(initialStyles);
	const [maximizeModal, setMaximizeModal] = useState<boolean>(false);
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const classes = useStyles();

	const handleOpen = () => {
		setOpen(true);
		setFooterStyles({
			height: !theme.breakpoints.down('sm') ? 'calc(100% - 60px)' : '',
		});
	};

	const handleClose = () => {
		setOpen(false);
		setFooterStyles(initialStyles);
	};

	window.addEventListener('message', ({ data, message, ...other }: any) => {
		if (data === 'finalizarCurso' || message === 'finalizarCurso') {
			if (selectedElement !== 0)
				actualizarElementosUbicacion(selectedElement);
		}
	});

	return (
		<>
			{elementsLocation.message && (
				<Paper className={classes.footer} style={footerStyles}>
					{elementsLocation.message}
				</Paper>
			)}
			{elementsLocation.numero_elementos > 0 && (
				<Paper className={classes.footer} style={footerStyles}>
					<>
						<Grid container spacing={1} alignItems="center">
							<Grid
								container
								item
								xs={12}
								sm={3}
								alignItems="center"
								justifyContent={matches ? 'center' : 'inherit'}
							>
								<img
									src={SaberMasIcon}
									alt="logoIconHouse"
									style={{ width: 110 }}
								/>
								<h4 style={{ color: '#293990' }}>
									Saber más para decidir mejor
								</h4>
							</Grid>
							<Grid item xs={7} sm={6}>
								{elementsLocation && (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: matches
												? 'column'
												: 'row',
										}}
									>
										<LinearProgress
											style={{
												background: '#C7C6C5',
												height: 8,
												width: matches ? '100%' : 200,
												marginRight: matches ? 0 : 10,
											}}
											color="info"
											variant="determinate"
											value={elementsLocation.avance}
										/>{' '}
										<span>
											{elementsLocation.avance}% de avance{' '}
											{elementsLocation.ubicacion !== 1
												? `para ${
														destinos[
															generalInfo
																.codigoDestino
														] || 'Comprar'
												  }`
												: ''}
										</span>
									</div>
								)}
							</Grid>
							<Grid
								container
								item
								xs={5}
								sm={3}
								justifyContent={matches ? 'center' : 'end'}
								alignItems="center"
							>
								<h5
									style={{
										color: theme.palette.primary.main,
									}}
								>
									{open ? 'Cerrar' : 'Abrir'}
								</h5>
								<IconButton
									onClick={open ? handleClose : handleOpen}
									style={{
										background: theme.palette.primary.main,
										color: 'white',
										marginLeft: 10,
									}}
								>
									{open ? (
										<KeyboardArrowDown />
									) : (
										<KeyboardArrowUp />
									)}
								</IconButton>
							</Grid>
						</Grid>
						<div
							className="cards-container"
							style={{
								display: open ? 'flex' : 'none',
								minHeight: 180,
							}}
						>
							{elementsLocation.elementos.map((el) => (
								<div className="card-info" key={el.idElemento}>
									{el.direccionUrl.includes('youtube') ? (
										<ReactPlayer
											controls
											url={el.direccionUrl}
											width={
												el.idElemento ===
												selectedElement
													? 500
													: 250
											}
											height={
												el.idElemento ===
												selectedElement
													? 250
													: 150
											}
											onPlay={() =>
												setSelectedElement(
													el.idElemento
												)
											}
											onEnded={() => {
												actualizarElementosUbicacion(
													el.idElemento
												);
												setSelectedElement(0);
											}}
										/>
									) : (
										<div
											style={{
												width: 250,
												height: 150,
												position: 'relative',
											}}
										>
											<iframe
												allowFullScreen
												id={el.nombre}
												title={el.nombre}
												src={el.direccionUrl}
												width="100%"
												height="100%"
											/>
											<ZoomOutMap
												onClick={() => {
													setMaximizeModal(true);
													setSelectedElement(
														el.idElemento
													);
												}}
												style={{
													bottom: 0,
													right: 0,
													position: 'absolute',
													cursor: 'pointer',
													color: 'cadetblue',
													background: 'aliceblue',
												}}
											/>
										</div>
									)}
									<div
										style={{
											paddingLeft: 15,
											paddingRight: 15,
											width: 250,
										}}
									>
										<label style={{ fontWeight: 500 }}>
											{el.nombre}
										</label>
										<p style={{ margin: 0 }}>
											<small>{el.descripcion}</small>
										</p>
										<RoundBtn
											variant="outlined"
											color={
												el.estatus === 0
													? 'warning'
													: 'success'
											}
											style={{
												fontSize: 12,
												width: 120,
												height: 30,
												background:
													el.estatus === 0
														? '#FFF4DE'
														: '#EEEEEE',
												color:
													el.estatus === 0
														? '#FFA800'
														: theme.palette.success
																.main,
											}}
											disabled={el.estatus !== 0}
										>
											{el.estatus === 0
												? 'Pendiente'
												: 'Completado'}
										</RoundBtn>
									</div>
								</div>
							))}
						</div>
					</>
				</Paper>
			)}
			<MaximizeElement
				open={maximizeModal}
				setOpen={setMaximizeModal}
				element={elementsLocation.elementos.find(
					(el) => el.idElemento === selectedElement
				)}
				setSelectedElement={setSelectedElement}
			/>
		</>
	);
};

export default Footer;
