import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { grey, red } from '@mui/material/colors';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			top: 0,
			position: 'absolute',
			width: 'calc(100% - 20vw)',
			padding: '30px 10vw',
			'&>h4': {
				margin: 0,
				color: red[500],
				display: 'flex',
				alignItems: 'center',
			},
		},
		container: {
			marginTop: 85,
			background: grey[50],
			position: 'absolute',
			padding: '30px 15vw',
			overflow: 'auto',
			width: 'calc(100% - 30vw)',
			maxHeight: 'calc(100vh - 330px)',
			minHeight: 'calc(100% - 265px)',
			[theme.breakpoints.down('md')]: {
				maxHeight: 'calc(100% - 265px)',
				padding: '30px 10vw',
				width: 'calc(100% - 20vw)',
			},
			[theme.breakpoints.down('sm')]: {
				minHeight: 'calc(100% - 365px)',
				maxHeight: 'calc(100% - 365px)',
				width: 'calc(100% - 20vw)',
				padding: '30px 10vw',
			},
		},
		footer: {
			overflow: 'auto',
			// position: 'absolute',
			padding: '25px 5vw',
			// width: 'calc(100% - 20vw)',
			maxHeight: 'calc(100% - 60px)',
			background: `${grey[300]} !important`,
			'&>*': {
				color: 'black',
			},
			'&>.cards-container': {
				overflowX: 'auto',
				'&>.card-info': {
					display: 'flex',
					padding: 5,
					[theme.breakpoints.down('md')]: {
						flexDirection: 'column',
					},
				},
			},
		},
	})
);
