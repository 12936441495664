/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { keyAPI } from '../../api/modules/key';
import { returnSavingsAPI } from '../../api/modules/returnSavings';
import { IAlert } from '../../interfaces/alert';
import { encrypt } from '../../utils/encrypt';
import { dataReturnSavingsContext } from './contextData';
import {
	AclaracionDevoluciónSSVEnum,
	AclaracionesFondoAhorroEnum,
	BeneficiariosSSVEnum,
	BusquedaCasosResponseCodes,
	catalogoAfore,
	catalogoEntidades,
	consultaBanco,
	ConsultaDatosDisponibilidad,
	ConsultaDatosResponseCodes,
	CrearCaso,
	CuentaDestinoCodes,
	DevDemandaEfirmaEnum,
	DevFondosPresencialEnum,
	DisponibilidadEstatusEnum,
	FondoAhorroBeneficiarioUnicoEnum,
	FondoAhorroBenMultiplesEnum,
	NuevoPensionadoDigitalSSVEnum,
	persisteDatos,
	RechazosDisponibilidadEnum,
	RespuestasSiciEnum,
	ValidaAfore,
	validaCausaRaiz,
	ValidaFecha,
	ValidaPension,
} from './enums';
import {
	CaseInformation,
	IConsultaBancoResponse,
	IConsultaDatos,
	ICreateAfore,
	ICreateEntidades,
	ICuentaDestino,
	IDataReturnSavings,
	IReponseCreacionCaso,
	IRequestAfore,
	IRequestAttachDocument,
	IRequestBusquedaCasos,
	IRequestConsultaBanco,
	IRequestConsultaDatosDisponibilidad,
	IRequestConsultaDocumentacion,
	IRequestCreacionCaso,
	IRequestMarcarCuenta,
	IRequestObtieneArchivoAcuse,
	IRequestPension,
	IRequestPersisteDatos,
	IRequestSignDocument,
	IRequestValidaCausaRaiz,
	IRequestValidaCausaRaiz2,
	IRequestValidaFecha,
	IResponseAttachDocument,
	IResponseBusquedaCasos,
	IResponseConsultaDatosDisponibilidad,
	IResponseConsultaDocumentacion,
	IResponseConsultaSaldoCero,
	IResponsePersisteDatos,
	IResponseReintentoPDFAcuse,
	IResponseValidaAfore,
	IResponseValidaCausaRaiz,
	IResponseValidaFecha,
	IResponseValidaPension,
	returnOptionType,
	IDataException,
	IResponseMarcarCuenta,
} from './interfaces';
import { getArrayFlow, getNumberPage } from './utils';

import { makeDate24API } from '../../api/modules/makeDate24';
import { encodeAllJSONData } from '../../utils/encrypt';
import { IDataCitasActuales } from '../../interfaces/makeDate24';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import useCustomScript from '../../hooks/useCustomScript';

interface props {
	children: JSX.Element;
}

declare global {
	interface Window {
		firmar: any;
	}
}

export const DATA_INITIAL: IDataReturnSavings = {
	returnOptionType: '',
};

export const Dataprovider = ({ children }: props) => {
	/* STATES */
	const [showModalTemporal, setShowModalTemporal] = useState<boolean>(false);
	const [data, setData] = useState<IDataReturnSavings>();
	const [MessageToView, setMessageToView] = useState<string | undefined>(
		undefined
	);
	const [states, setStates] = useState<{ value: string; text: string }[]>([]);
	const [page, setPage] = useState<number>(0);
	const [alert, setAlert] = useState({
		show: false,
		severity: 'success',
		message: '',
	} as IAlert);
	const [loading, setLoading] = useState(false);
	const getMessageErrorDefault: string =
		'Por el momento el servicio no se encuentra disponible, intenta más tarde.';
	const [dataException, setDataException] = useState({
		title: 'Número de Seguridad Social erróneo',
		list: '',
		question: '¿Quieres hacer una aclaración?',
		action: 'aclaracion',
	} as IDataException);

	//Make Date
	const { user } = useSelector((state: RootState) => state.session);
	const [citaActual, setCitaActual] = useState<{
		AppointmentCode: string;
		CreationDate: string;
		Date: string;
		Id: string;
		caso: string;
		OrganizationDes: string;
		OrganizationLocation: string;
		Sevice: {
			ServiceCode: string;
			ServiceDes: string;
		};
		TS: string;
	}>({
		AppointmentCode: '',
		CreationDate: '',
		Date: '',
		Id: '',
		caso: '',
		OrganizationDes: '',
		OrganizationLocation: '',
		Sevice: {
			ServiceCode: '',
			ServiceDes: '',
		},
		TS: '',
	});
	const [doctosRequeridosArray, setDoctosRequeridosArray] = useState('');
	const [doctosRequeridos, setDoctosRequeridos] = useState('');
	const [dataEfirm, setDataEfirm] = useState('' as any);
	const [pdfSigned, setPdfSigned] = useState(false);
	const [optionSelectedItem, setOptionSelectedItem] = useState('')
	useCustomScript('/jquery/jquery.min.js');
	useCustomScript('/efirma/firma-documento.js');
	useCustomScript('/efirma/fiel-validacion.ofu.min.js');
	var serverIP = process.env.REACT_APP_URL_VALIDA_FIEL;
	const firmar = window.firmar;

	/* NAVIGATE PAGES */
	const GetPage = (
		currentPage: number,
		isNext: boolean,
		accordingAmount?: boolean,
		viewMessageScreen = false
	) => {
		const arrayFlow: number[] = getArrayFlow(
			data?.returnOptionType || '',
			currentPage || 0,
			accordingAmount,
			data?.hasDemand,
			viewMessageScreen,
			data?.isReintentoSolicitud,
			data?.grupo,
			data?.typeDemand,
			data?.isDate
		);
		const newValue = getNumberPage(arrayFlow, currentPage, isNext);
		setPage(newValue);
	};
	const handleGoHomePage = () => {
		handleGoPage(0);
	};
	const handleGoPage = (page: number) => {
		const arrayFlow: number[] = getArrayFlow(
			data?.returnOptionType || '',
			page || 0,
			data?.accordingAmount,
			data?.hasDemand,
			page === 18 ? true : false,
			data?.isReintentoSolicitud,
			data?.grupo,
			data?.typeDemand,
			data?.isDate
		);
		const indexFlow: number = arrayFlow.findIndex(
			(num) => num === page || 0
		);
		let newIndexFlow: number = indexFlow - 1;
		if (arrayFlow.length === 1) {
			newIndexFlow = indexFlow;
		}
		const newPage =
			arrayFlow[newIndexFlow] !== undefined
				? arrayFlow[newIndexFlow]
				: -1;

		GetPage(
			newPage,
			true,
			data?.accordingAmount,
			page === 18 ? true : false
		);
	};
	const handleGoDate = () => {
		const params: IRequestCreacionCaso = {
			evento: '5',
			opcion: optionSelectedItem,
			demanda: false,
			tipoDemanda: '',
			entidad: '09',
			montoDevolver: data?.ConsultaDatosDisponibilidadResponse?.data?.ahorroTotal || '0',
			montoSaldo92: data?.ConsultaDatosDisponibilidadResponse?.data?.montoSaldo92 || '0',
			montoSaldo97: data?.ConsultaDatosDisponibilidadResponse?.data?.montoSaldo97 || '0',
			montoSaldoFA: data?.ConsultaDatosDisponibilidadResponse?.data?.subCuenta || '0',
			montoAdicionalSaldoFA: data?.ConsultaDatosDisponibilidadResponse?.data?.cantidadAdicional || '0',
			descMontoAdicionalSaldoFA: data?.ConsultaDatosDisponibilidadResponse?.data?.desRechazoFa,
			numeroCredito: '',
			codigoRechazoFa: data?.ConsultaDatosDisponibilidadResponse?.data?.codRechazoFa || '',
			grupo: data?.ConsultaDatosDisponibilidadResponse?.data?.grupo || '',
		} as IRequestCreacionCaso;
		handleCreacionCaso(params);
	};
	const handleNextPage = () => {
		const according: boolean = data?.accordingAmount !== undefined ? data?.accordingAmount : true;
		GetPage(page || 0, true, according);
	};
	const handlePrevPage = () => {
		const according: boolean =
			data?.accordingAmount !== undefined ? data?.accordingAmount : true;
		GetPage(page || 0, false, according);
	};

	/* FUNCTIONS */

	const AlertReset = () => {
		setAlert({
			show: false,
			severity: 'success',
			message: '',
		} as IAlert);
	};
	const continuaSolicitud = (_caseInformation: CaseInformation) => {
		handleconsultaDatosDisponibilidadContinuaSolicitud(_caseInformation);
	};
	function getCausalDesc(
		value: string | undefined
	): returnOptionType | undefined {
		return (value || '') === '1'
			? 'terminacionLaboral'
			: (value || '') === '2'
				? 'resolucionPensionImss'
				: (value || '') === '3'
					? 'planPrivadoPension'
					: (value || '') === '4'
						? 'porInconformidades'
						: (value || '') === '5'
							? 'pensionAnterior1997'
							: undefined;
	}

	/* ENDPOINTS CALLS */
	const handleConsultaDatos = () => {
		setData({
			...data,
			ConsultaDatosResponse: undefined,
		});
		try {
			setLoading(true);
			AlertReset();
			returnSavingsAPI
				.consultaDatos()
				.then((response: IConsultaDatos) => {
					if (response.code === 'M0099') {
						setShowModalTemporal(true);
						return;
					}
					setData({
						...data,
						ConsultaDatosResponse: response,
						returnOptionType: getCausalDesc(response.data?.causal),
						grupo: response.data?.grupo || undefined,
						causal: response.data?.causal || undefined,
					});
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error?.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handlebusquedaCasos = async () => {
		setData({
			...data,
			BusquedaCasosResponse: undefined,
		});
		setLoading(true);
		AlertReset();
		try {
			const params: IRequestBusquedaCasos = {
				claseOperacion: data?.claseOperacion || '',
				noCaso: data?.noCaso || '',
			} as IRequestBusquedaCasos;

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.busquedaCasos({ data: encryptedarameters })
				.then((response: IResponseBusquedaCasos) => {
					setData({
						...data,
						BusquedaCasosResponse: response,
						noCaso: response.data?.noCaso,
						tipificacion: response.data?.tipificacion,
						claseOperacion: response.data?.claseOperacion,
						caseInformation: {
							numberCase: response.data?.noCaso,
							requestDate: response.data?.fecha,
							service: response.data?.descripcion,
							status: response.data?.estatusDesc,
							statusDate: response.data?.fechaEstatus,
							description: response.data?.descripcionEstatus,
						},
					});
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error?.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handlebusquedaCasosPersiste = async () => {
		setLoading(true);
		AlertReset();
		try {
			const params: IRequestBusquedaCasos = {
				claseOperacion: data?.claseOperacion || '',
				noCaso: data?.noCaso || '',
			} as IRequestBusquedaCasos;

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);
			returnSavingsAPI
				.busquedaCasos({ data: encryptedarameters })
				.then((response: IResponseBusquedaCasos) => {
					setData({
						...data,
						noCaso: response.data?.noCaso,
						tipificacion: response.data?.tipificacion,
						claseOperacion: response.data?.claseOperacion,
						caseInformation: {
							numberCase: response.data?.noCaso,
							requestDate: response.data?.fecha,
							service: response.data?.descripcion,
							status: response.data?.estatusDesc,
							statusDate: response.data?.fechaEstatus,
							description: response.data?.descripcionEstatus, //TODO.- Corregir estilos se encima el texto que salte a la linea de abajo!
						},
					});

					if (response.code === '0000') {
						if (data?.CreacionCasoResponse === undefined) {
							setLoading(false)
							handleGoPage(14);
							return;
						} else if (getCausalDesc(data?.causal) === 'terminacionLaboral' ||
							getCausalDesc(data?.causal) === 'resolucionPensionImss' ||
							getCausalDesc(data?.causal) === 'planPrivadoPension'
						) {
							//Crear Marca
							let paramsPremarca = {
								grupo: data?.grupo || '',
								entidadCaso: data?.returnSavingsStates || '',
								claseOperacion: response.data?.claseOperacion,
								caso: response.data?.noCaso,
								clabe: data?.bankData?.CLABE || '',
								opcion: data?.returnOptionType || '',
								nrp: data?.nrpNumber || '',
							} as IRequestMarcarCuenta;
							handleMarcarCuenta(paramsPremarca);
						} else {
							setLoading(false)
							handleGoPage(14);
							return;
						}
					}
				})
				.catch((error: any) => {
					setLoading(false)
					setAlert({
						show: true,
						severity: 'error',
						message: error?.description || getMessageErrorDefault,
					} as IAlert);
				})
		} catch (error) {
			setLoading(false)
			setAlert({
				show: true,
				severity: 'error',
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handleConsultaSaldoCero = async () => {
		setData({
			...data,
			ConsultaSaldoCeroResponse: undefined,
		});
		setLoading(true);
		AlertReset();
		try {
			returnSavingsAPI
				.consultaSaldoCero()
				.then((response: IResponseConsultaSaldoCero) => {
					setData({
						...data,
						ConsultaSaldoCeroResponse: response,
					});
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: error.description || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handleconsultaDatosDisponibilidadContinuaSolicitud = async (
		_caseInformation?: CaseInformation
	) => {
		setData({
			...data,
			ConsultaDatosDisponibilidadContinuaSolicitudResponse: undefined,
		});
		setLoading(true);
		AlertReset();
		setMessageToView(undefined);
		try {
			const params: IRequestConsultaDatosDisponibilidad = {
				causal: data?.returnOptionType || '',
				nrp: '',
			} as IRequestConsultaDatosDisponibilidad;

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.consultaDatosDisponibilidadContinuaSolicitud({ data: encryptedarameters })
				.then((response: IResponseConsultaDatosDisponibilidad) => {
					setData({
						...data,
						ConsultaDatosDisponibilidadContinuaSolicitudResponse:
							response,
						caseInformation:
							_caseInformation !== undefined
								? _caseInformation
								: data?.caseInformation,
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: error.description || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handleConsultaDatosDisponibilidad = async () => {
		setData({
			...data,
			ConsultaDatosDisponibilidadResponse: undefined,
		});
		setLoading(true);
		AlertReset();
		setMessageToView(undefined);
		try {
			const params: IRequestConsultaDatosDisponibilidad = {
				causal: data?.returnOptionType || '',
				nrp: data?.nrpNumber || '',
			} as IRequestConsultaDatosDisponibilidad;
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.consultaDatosDisponibilidad({ data: encryptedarameters })
				.then((response: IResponseConsultaDatosDisponibilidad) => {
					setData({
						...data,
						ConsultaDatosDisponibilidadResponse: response,
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: error.description || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handleCreacionCaso = async (
		params: IRequestCreacionCaso,
		requestPersisteDatos: IRequestPersisteDatos = {
			grupo: '',
			opcion: '',
		}
	) => {
		setData({
			...data,
			CreacionCasoResponse: undefined,
		});
		setLoading(true);
		AlertReset();
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.creacionCaso({ data: encryptedarameters })
				.then((response: IReponseCreacionCaso) => {
					setData({
						...data,
						CreacionCasoResponse: response,
						noCaso: response.data?.ordenServicio,
						claseOperacion: response.data?.claseOperacion,
						tipificacion: response.data?.tipificacion,
						grupo:
							response.data?.grupo !== ''
								? response.data?.grupo
								: data?.grupo,
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handleCuentaDestino = async (value: boolean) => {
		setData({
			...data,
			CuentaDestinoResponse: undefined,
			accordingAmount: undefined,
		});
		setLoading(true);
		AlertReset();
		try {
			returnSavingsAPI
				.cuentaDestino()
				.then((response: ICuentaDestino) => {
					setData({
						...data,
						CuentaDestinoResponse: response,
						accordingAmount: value,
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handleGetAfore = () => {
		setData({
			...data,
			catalogoAforeResponse: undefined,
		});
		setLoading(true);
		AlertReset();
		try {
			returnSavingsAPI
				.catalogoAfore()
				.then((response: ICreateAfore) => {
					setData({
						...data,
						catalogoAforeResponse: response,
					});
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						message: error.description || getMessageErrorDefault,
						severity: 'error',
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch {
			setAlert({
				show: true,
				message: getMessageErrorDefault,
				severity: 'error',
			} as IAlert);
		}
	};
	const handleValidaAfore = async (optionSelected: string) => {
		setData({
			...data,
			afore: undefined,
			ValidaAforeResponse: undefined,
		});
		setLoading(true);
		AlertReset();
		try {
			const params: IRequestAfore = {
				afore: optionSelected,
			} as IRequestAfore;

			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);
			returnSavingsAPI
				.validaAfore({ data: encryptedarameters })
				.then((response: IResponseValidaAfore) => {
					setData({
						...data,
						afore: optionSelected,
						ValidaAforeResponse: response,
					});
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						message: error.description || getMessageErrorDefault,
						severity: 'error',
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch { }
	};
	const handleValidaCausaRaiz = async (
		values: IRequestValidaCausaRaiz2,
		paramasSignDocument?: IRequestSignDocument
	) => {
		setData({
			...data,
			ValidaCausaRaizResponse: undefined,
			certifiedFiles: undefined,
			requestSignDocument: undefined,
		});
		setLoading(true);
		AlertReset();
		try {
			const params: IRequestValidaCausaRaiz = {
				rfc: values.rfc,
				nombreCompleto: values.nombreCompleto,
				curp: values.curp,
			};
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.validaCausaRaiz({ data: encryptedarameters })
				.then((response: IResponseValidaCausaRaiz) => {
					setData({
						...data,
						ValidaCausaRaizResponse: response,
						certifiedFiles: {
							cerFile: values.files.cer,
							keyFile: values.files.key,
							privatKey: values.password,
						},
						requestSignDocument: paramasSignDocument,
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || getMessageErrorDefault,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				show: true,
				message: error || getMessageErrorDefault,
				severity: 'error',
			} as IAlert);
		}
	};
	const handleGetStates = () => {
		setData({ ...data, catalogoEntidades: undefined });
		setLoading(true);
		setStates([]);
		AlertReset();
		try {
			returnSavingsAPI
				.catalogoEntidades()
				.then((response: ICreateEntidades) => {
					setData({ ...data, catalogoEntidades: response });
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						message: error.description || getMessageErrorDefault,
						severity: 'error',
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch {
			setAlert({
				show: true,
				message: getMessageErrorDefault,
				severity: 'error',
			} as IAlert);
		}
	};
	const handleValidaFechas = async (params: IRequestValidaFecha) => {
		setData({
			...data,
			ValidaFechasResponse: undefined,
			pensionResolutionDate: undefined,
		});
		setLoading(true);
		AlertReset();
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.validaFechas({ data: encryptedarameters })
				.then((response: IResponseValidaFecha) => {
					setData({
						...data,
						ValidaFechasResponse: response,
						pensionResolutionDate: {
							startDate: params.fechaInicio || undefined,
							resolutionDate: params.fechaFin || undefined,
						},
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || getMessageErrorDefault,
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (error: any) {
			setLoading(false);
			setAlert({
				show: true,
				message: error.description || getMessageErrorDefault,
				severity: 'error',
			} as IAlert);
		}
	};
	const handleValidaPension = async (typePension: string) => {
		setData({
			...data,
			ValidaPensionResponse: undefined,
			typePension: undefined,
		});
		setLoading(true);
		AlertReset();
		try {
			const params: IRequestPension = {
				pension: typePension,
			};
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.validaPension({ data: encryptedarameters })
				.then((response: IResponseValidaPension) => {
					setData({
						...data,
						ValidaPensionResponse: response,
						typePension: typePension,
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setLoading(false);
			setAlert({
				show: true,
				severity: 'error',
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handlerConsultaBanco = async (params: IRequestConsultaBanco) => {
		setData({
			...data,
			consultaBancoResponse: undefined,
			bankData: undefined,
		});
		setLoading(true);
		AlertReset();
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.consultaBanco({ data: encryptedarameters })
				.then((response: IConsultaBancoResponse) => {
					setData({
						...data,
						consultaBancoResponse: response,
						bankData: {
							CLABE: params.clabe,
							bank: response.data?.banco || '',
							name: response.data?.nombreCompleto || '',
						},
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				severity: 'error',
				show: true,
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handlePersisteDatos = async (params: IRequestPersisteDatos) => {
		setData({ ...data, persisteDatosResponse: undefined });
		setLoading(true);
		AlertReset();
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.persisteDatos({ data: encryptedarameters })
				.then((response: IResponsePersisteDatos) => {
					setData({ ...data, persisteDatosResponse: response });
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				severity: 'error',
				show: true,
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handlePersisteOnly = async (params: IRequestPersisteDatos) => {
		setData({ ...data, persisteDatosResponse: undefined });
		setLoading(true);
		AlertReset();
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);
			returnSavingsAPI
				.persisteDatos({ data: encryptedarameters })
				.then((response: IResponsePersisteDatos) => {
					setData({ ...data, persisteDatosResponse: response, persisteDatosResponseOnly: true });
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				severity: 'error',
				show: true,
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handleMarcarCuenta = async (params: IRequestMarcarCuenta) => {
		setData({ ...data, marcarCuentaResponse: undefined });
		setLoading(true);
		AlertReset();
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);
			returnSavingsAPI
				.marcarCuenta({ data: encryptedarameters })
				.then((response: IResponseMarcarCuenta) => {
					setData({
						...data,
						marcarCuentaResponse: response
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
					setLoading(false)
				})
		} catch (error) {
			setAlert({
				severity: 'error',
				show: true,
				message: error || getMessageErrorDefault,
			} as IAlert);
			setLoading(false)
		}
	};
	const handlerObtieneArchivoAcuse = async (
		params: IRequestObtieneArchivoAcuse,
		_caseInformation?: CaseInformation
	) => {
		setData({ ...data, ObtieneArchivoAcuseResponse: undefined });
		setLoading(true);
		AlertReset();
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.obtieneArchivoAcuse({ data: encryptedarameters })
				.then((response: any) => {
					if (_caseInformation === undefined) {
						setData({
							...data,
							ObtieneArchivoAcuseResponse: response,
						});
					} else {
						setData({
							...data,
							ObtieneArchivoAcuseResponse: response,
							caseInformation: _caseInformation,
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				severity: 'error',
				show: true,
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handlerConsultaDocumentacion = async (
		params: IRequestConsultaDocumentacion
	) => {
		setData({ ...data, ConsultaDocumentacionResponse: undefined });
		setLoading(true);
		AlertReset();
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.consultaDocumentacion({ data: encryptedarameters })
				.then((response: IResponseConsultaDocumentacion) => {
					setData({
						...data,
						ConsultaDocumentacionResponse: response,
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				severity: 'error',
				show: true,
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handlerReintentoPDFAcuse = async (
		_caseInformation?: CaseInformation
	) => {
		setData({ ...data, ReintentoPDFAcuseResponse: undefined });
		setLoading(true);
		AlertReset();
		try {
			returnSavingsAPI
				.reintentoPDFAcuse()
				.then((response: IResponseReintentoPDFAcuse) => {
					setData({
						...data,
						ReintentoPDFAcuseResponse: response,
						caseInformation: _caseInformation !== undefined ? _caseInformation : data?.caseInformation,
						isReintentoSolicitud: true,
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				severity: 'error',
				show: true,
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handlecitasActuales = async (
		nss: string | any,
		dataResponse: any
	) => {
		try {
			setLoading(true);
			const data: IDataCitasActuales = {
				pAppointmentCode: '',
				nss: nss || ''
			}
			const dataEncript = await encodeAllJSONData({ data: JSON.stringify(data), }, user?.k || '');
			makeDate24API
				.citasActuales(dataEncript)
				.then((response) => {
					if (response.code === '0000') {
						let hasDate = false;
						response.data.citas.forEach(async (element: any) => {
							if (element.ServiceId === '0320') {
								setCitaActual(element);
								hasDate = true;
							}
							const params = {
								pOrganizationCode: element?.organizationCode,
								pServiceId: element?.serviceId,
								nss: nss || ''
							};
							const dataEncript = await encodeAllJSONData(
								{
									data: JSON.stringify(params),
								},
								user?.k || ''
							);
							const responseDoctosRequeridos = await makeDate24API.documentosRequeridos(dataEncript);
							if (responseDoctosRequeridos.code === '0000') {
								const listado = saltosDeLinea(
									responseDoctosRequeridos?.data?.requirementsList[0]?.requirementText
								);
								if (listado && listado.length > 0) {
									const listadoFormateado = listado.map((element: any) => stripHTML(element));
									const listadoFormateadoJoin = listadoFormateado.join('||**');
									setDoctosRequeridosArray(listadoFormateadoJoin);
									setDoctosRequeridos(listado);
								}
							}
						});
						if (hasDate) {
							setData({
								...data,
								caseInformation: {
									numberCase: dataResponse.data?.noCaso,
									requestDate: dataResponse.data?.fecha,
									service: dataResponse.data?.descripcion,
									status: dataResponse.data?.estatusDesc,
									statusDate: dataResponse.data?.fechaEstatus,
									description:
										dataResponse.data?.descripcionEstatus,
									btnCustom: {
										label: 'Ver Cita',
										visible: true,
										onClickOption: 3,
									},
								},
							});
						} else {
							setData({
								...data,
								caseInformation: {
									numberCase: dataResponse.data?.noCaso,
									requestDate: dataResponse.data?.fecha,
									service: dataResponse.data?.descripcion,
									status: dataResponse.data?.estatusDesc,
									statusDate: dataResponse.data?.fechaEstatus,
									description:
										dataResponse.data?.descripcionEstatus,
									btnCustom: {
										label: 'Hacer Cita',
										visible: true,
										onClickOption: 3,
									},
								},
							});
						}
						handleGoPage(14);
					}
				})
				.catch((err: any) => {
					if (!err.res || !err.res.id || err.res.id !== '2') {
						setAlert({
							show: true,
							severity: 'error',
							message: err.description || getMessageErrorDefault,
						} as IAlert);
					} else {
						setData({
							...data,
							caseInformation: {
								numberCase: dataResponse.data?.noCaso,
								requestDate: dataResponse.data?.fecha,
								service: dataResponse.data?.descripcion,
								status: dataResponse.data?.estatusDesc,
								statusDate: dataResponse.data?.fechaEstatus,
								description:
									dataResponse.data?.descripcionEstatus, //TODO.- Corregir estilos se encima el texto que salte a la linea de abajo!
								btnCustom: {
									label: 'Hacer Cita',
									visible: true,
									onClickOption: 3,
								},
							},
						});
					}
				})
				.finally(() => {
					setLoading(false);
					setPage(14);
				});
		} catch (err: any) {
			setAlert({
				show: true,
				severity: 'error',
				message: err.description || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const saltosDeLinea = (docs: any) => {
		let str = docs;
		let search = '';
		let replacer;

		str = str.replace(replacer, '\n');

		search = '</p>';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '');

		search = '<br />';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '\n');

		search = '&nbsp;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, ' ');

		search = '&gt;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '->');

		search = '&bull;';
		replacer = new RegExp(search, 'g');
		str = str.replace(replacer, '');
		str = str.split('<p>');

		str = str.map((a: any) => {
			return a.trim();
		});

		return str;
	};
	const stripHTML = (html: any) => {
		let div = document.createElement('div');
		div.innerHTML = html;
		return div.textContent || div.innerText || '';
	};
	const handlerAttachDocument = async (params: IRequestAttachDocument) => {
		setData({ ...data, AttachDocumentResponse: undefined, startSignDocument: undefined });
		setLoading(true);
		AlertReset();
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const encryptedarameters = await encrypt(
				JSON.stringify(params),
				key
			);

			returnSavingsAPI
				.adjuntarArchivo({ data: encryptedarameters })
				.then((response: IResponseAttachDocument) => {
					setPage(14)
					setData({
						...data,
						AttachDocumentResponse: response,
						ObtieneArchivoAcuseResponse: {
							data: {
								documento: params.archivo,
								fechaCreacion: '',
								nombreArchivo: data?.noCaso + '_Acuse_firmado.pdf',
								numeroCaso: data?.noCaso,
								tamanoArchivo: '',
								usuarioCreado: ''
							},
							code: '',
							message: ''
						}
					});
				})
				.catch((error) => {
					setAlert({
						show: true,
						severity: 'error',
						message: error.description || getMessageErrorDefault,
					} as IAlert);
				})
				.finally(() => setLoading(false));
		} catch (error) {
			setAlert({
				severity: 'error',
				show: true,
				message: error || getMessageErrorDefault,
			} as IAlert);
		}
	};
	const handleSubmitFirmar = (documento: any) => {
		var inputs = {
			keyBinary: dataEfirm.keyBinary,
			cerBinary: dataEfirm.cerBinary,
			cerB64: dataEfirm.cerB64,
			pdfB64: documento,
			pdfContentType: 'application/pdf',
			password: dataEfirm.password,
			serverIP: serverIP,
		};
		firmar(inputs, (error: any, response: any) => {
			if (error === null) {
				setLoading(false);
				setPdfSigned(true)
				handlerAttachDocument({
					caso: data?.noCaso,
					archivo: response.pdfSignedB64
				} as IRequestAttachDocument);
			} else {
				setLoading(false);
				setAlert({
					show: true,
					message: error,
					severity: 'error',
				});
			}
		});
	};

	/* PRINT DATA */
	useEffect(() => {
		setAlert({ message: '', show: false, severity: 'success' } as IAlert);
	}, [page]);
	/* CHANGE RESPONSE'S */
	useEffect(() => {
		let response = data?.ConsultaDatosResponse;
		if (response === undefined) {
			return;
		}

		if (response.code === ConsultaDatosResponseCodes.ERROR) {
			setData({
				...data,
				returnOptionType: undefined,
				grupo: undefined,
				causal: undefined,
			});
			setAlert({
				show: true,
				severity: 'error',
				message: response.message || getMessageErrorDefault,
			} as IAlert);
			return;
		}
		handlebusquedaCasos();
	}, [data?.ConsultaDatosResponse]);
	useEffect(() => {
		let response = data?.BusquedaCasosResponse;
		if (response === undefined) {
			return;
		}

		if (response.code === BusquedaCasosResponseCodes.TIENE_CASOS) {
			setData({
				...data,
				hasDemand:
					response.data?.claseOperacion === 'ZG1C'
						? true
						: response.data?.claseOperacion === 'ZG1D'
							? false
							: data?.hasDemand,
				caseInformation: {
					numberCase: response.data?.noCaso,
					requestDate: response.data?.fecha,
					service: response.data?.descripcion,
					status: response.data?.estatusDesc,
					statusDate: response.data?.fechaEstatus,
					description: response.data?.descripcionEstatus,
				},
			});

			switch (response.data?.claseOperacion) {
				case 'ZDVU': //Devolución de Fondos Presencial
					if (response.data?.estatus === DevFondosPresencialEnum.E0001 ||
						response.data?.estatus === DevFondosPresencialEnum.E0002 ||
						response.data?.estatus === DevFondosPresencialEnum.E0003
					) {
						// Mostrar BTN HAZ CITA
						handlecitasActuales(user?.nss, response);
						return;
					}
					handleGoPage(14);
					break;
				case 'ZG1C': //Dev. con demanda E-Firma
					if (response.data?.estatus === DevDemandaEfirmaEnum.E0002) {
						setData({
							...data,
							hasDemand: data?.hasDemand,
							caseInformation: {
								numberCase: response.data?.noCaso,
								requestDate: response.data?.fecha,
								service: response.data?.descripcion,
								status: response.data?.estatusDesc,
								statusDate: response.data?.fechaEstatus,
								description: response.data?.descripcionEstatus,
								btnCustom: {
									label: 'Adjuntar documentos',
									visible: true,
									onClickOption: 4,
								},
							},
						});
						handleGoPage(14);
					} else if (response.data?.estatus === DevDemandaEfirmaEnum.E0022 || response.data?.estatus === DevDemandaEfirmaEnum.E0003) {
						handlerObtieneArchivoAcuse(
							{
								caso: data?.noCaso,
							} as IRequestObtieneArchivoAcuse,
							{
								numberCase: response.data?.noCaso,
								requestDate: response.data?.fecha,
								service: response.data?.descripcion,
								status: response.data?.estatusDesc,
								statusDate: response.data?.fechaEstatus,
								description: response.data?.descripcionEstatus,
							}
						);
					} else if (response.data?.estatus === DevDemandaEfirmaEnum.E0024) {
						if (data?.marcarCuentaResponse === undefined) {
							let _caseInformation = {
								numberCase: response.data?.noCaso,
								requestDate: response.data?.fecha,
								service: response.data?.descripcion,
								status: response.data?.estatusDesc,
								statusDate: response.data?.fechaEstatus,
								description: 'Existió un problema con la solicitud, por favor reinténtalo.',
								btnCustom: {
									label: 'Reintentar',
									visible: true,
									onClickOption: 2,
								},
							} as CaseInformation;
							setData({
								...data,
								caseInformation: _caseInformation,
							});
							setPage(14);
						} else if (data?.marcarCuentaResponse?.data?.estadoSolicitudFa === RespuestasSiciEnum.ERROR ||
							data?.marcarCuentaResponse?.data?.estadoSolicitudLey73 === RespuestasSiciEnum.ERROR) {
							let _caseInformation = {
								numberCase: response.data?.noCaso,
								requestDate: response.data?.fecha,
								service: response.data?.descripcion,
								status: response.data?.estatusDesc,
								statusDate: response.data?.fechaEstatus,
								description: 'Existió un problema con la solicitud, por favor reinténtalo.',
								btnCustom: {
									label: 'Reintentar',
									visible: true,
									onClickOption: 9,
								},
							} as CaseInformation;
							setData({
								...data,
								caseInformation: _caseInformation,
								isReintentoSolicitud: true
							});
							setPage(14);

						} else {
							continuaSolicitud({
								numberCase: response.data?.noCaso,
								requestDate: response.data?.fecha,
								service: response.data?.descripcion,
								status: response.data?.estatusDesc,
								statusDate: response.data?.fechaEstatus,
								description: response.data?.descripcionEstatus,
							});
						}
					}
					break;
				case 'ZG1D': //Nuevo Pensionado Digital SSV
					if (data?.marcarCuentaResponse === undefined) {
						if (response.data?.estatus === NuevoPensionadoDigitalSSVEnum.E0019) {
							let _caseInformation = {
								numberCase: response.data?.noCaso,
								requestDate: response.data?.fecha,
								service: response.data?.descripcion,
								status: response.data?.estatusDesc,
								statusDate: response.data?.fechaEstatus,
								description: 'Existió un problema con la solicitud, por favor reinténtalo.',
								btnCustom: {
									label: 'Reintentar',
									visible: true,
									onClickOption: 9,
								},
							} as CaseInformation;
							setData({
								...data,
								caseInformation: _caseInformation,
								isReintentoSolicitud: true
							});
							setPage(14);
						} else if (response.data?.estatus === NuevoPensionadoDigitalSSVEnum.E0002) {
							handlerObtieneArchivoAcuse(
								{
									caso: response.data?.noCaso,
								} as IRequestObtieneArchivoAcuse,
								{
									numberCase: response.data?.noCaso,
									requestDate: response.data?.fecha,
									service: response.data?.descripcion,
									status: response.data?.estatusDesc,
									statusDate: response.data?.fechaEstatus,
									description:
										response.data?.descripcionEstatus,
								}
							);
						}
					} else if (
						data?.marcarCuentaResponse?.data?.estadoSolicitudFa ===
						RespuestasSiciEnum.ERROR ||
						data?.marcarCuentaResponse?.data
							?.estadoSolicitudLey73 === RespuestasSiciEnum.ERROR
					) {
						let _caseInformation = {
							numberCase: response.data?.noCaso,
							requestDate: response.data?.fecha,
							service: response.data?.descripcion,
							status: response.data?.estatusDesc,
							statusDate: response.data?.fechaEstatus,
							description:
								'Existió un problema con la solicitud, por favor reinténtalo.',
							btnCustom: {
								label: 'Reintentar',
								visible: true,
								onClickOption: 0,
							},
						} as CaseInformation;

						setData({
							...data,
							caseInformation: _caseInformation,
						});
						setPage(14);
					}
					//--------------------------------------------------
					break;
				case 'ZAFA': //Aclaraciones Fondo de Ahorro
					if (response.data?.estatus === AclaracionesFondoAhorroEnum.E0002) {
						setData({
							...data,
							caseInformation: {
								numberCase: response.data?.noCaso,
								requestDate: response.data?.fecha,
								service: response.data?.descripcion,
								status: response.data?.estatusDesc,
								statusDate: response.data?.fechaEstatus,
								description: response.data?.descripcionEstatus,
								btnCustom: {
									label: 'Adjuntar documentos',
									visible: true,
									onClickOption: 4,
								},
							},
						});
						setPage(14);
					} else {
						setData({
							...data,
							caseInformation: {
								numberCase: response.data?.noCaso,
								requestDate: response.data?.fecha,
								service: response.data?.descripcion,
								status: response.data?.estatusDesc,
								statusDate: response.data?.fechaEstatus,
								description: response.data?.descripcionEstatus,
							},
						});
						setPage(14);
					}
					break;
				case 'ZASV': //Aclaración Devolución SSV
					if (response.data?.estatus === AclaracionDevoluciónSSVEnum.E0002) {
						// 	this.getVista().getBtnDoctos().setVisible(true); //???????????????????????
						//TODO.- Mostrar pantalla de carga de Documentos
						setData({
							...data,
							caseInformation: {
								numberCase: response.data?.noCaso,
								requestDate: response.data?.fecha,
								service: response.data?.descripcion,
								status: response.data?.estatusDesc,
								statusDate: response.data?.fechaEstatus,
								description: response.data?.descripcionEstatus,
								btnCustom: {
									label: 'Adjuntar documentos',
									visible: true,
									onClickOption: 4,
								},
							},
						});
						setPage(14);
					} else {
						setData({
							...data,
							BusquedaCasosResponse: response,
							noCaso: response.data?.noCaso,
							tipificacion: response.data?.tipificacion,
							claseOperacion: response.data?.claseOperacion,
							caseInformation: {
								numberCase: response.data?.noCaso,
								requestDate: response.data?.fecha,
								service: response.data?.descripcion,
								status: response.data?.estatusDesc,
								statusDate: response.data?.fechaEstatus,
								description: response.data?.descripcionEstatus,
							},
						});
						setPage(14);
					}
					// this.getVista().getBtnStart().setVisible(true);
					break;
				case 'ZFAB': //Fondo de Ahorro - Beneficiario Único
					if (
						response.data?.estatus ===
						FondoAhorroBeneficiarioUnicoEnum.E0002
					) {
						// 	this.getVista().getBtnHazCita().setVisible(true);
					} else if (
						response.data?.estatus ===
						FondoAhorroBeneficiarioUnicoEnum.E0003
					) {
						// 	this.getVista().getBtnVerCita().setVisible(true);
					}
					break;
				case 'ZFAM': //Fondo de Ahorro-Beneficiarios Múltiples
					if (
						response.data?.estatus ===
						FondoAhorroBenMultiplesEnum.E0002
					) {
					} else if (
						response.data?.estatus ===
						FondoAhorroBenMultiplesEnum.E0005
					) {
						// 	this.getVista().getBtnHazCita().setVisible(true);
					} else if (
						response.data?.estatus ===
						FondoAhorroBenMultiplesEnum.E0006
					) {
						// 	this.getVista().getBtnVerCita().setVisible(true);
					}
					break;
				case 'ZG1B': //Beneficiarios SSV
					if (response.data?.estatus === BeneficiariosSSVEnum.E0002) {
						// 	this.getVista().getBtnHazCita().setVisible(true);
					} else if (
						response.data?.estatus === BeneficiariosSSVEnum.E0003
					) {
						// 	this.getVista().getBtnVerCita().setVisible(true);
					}
					break;
				case 'ZBIM': //Baja del IMSS no registrada
				case 'ZFAH': //Dev. Fondo de Ahorro
				case 'ZPPP': //Plan Privado de Pensión
				case 'ZFNE': //Negativa Devolución
					setData({
						...data,
						BusquedaCasosResponse: response,
						noCaso: response.data?.noCaso,
						tipificacion: response.data?.tipificacion,
						claseOperacion: response.data?.claseOperacion,
						caseInformation: {
							numberCase: response.data?.noCaso,
							requestDate: response.data?.fecha,
							service: response.data?.descripcion,
							status: response.data?.estatusDesc,
							statusDate: response.data?.fechaEstatus,
							description: response.data?.descripcionEstatus,
						},
					});
					setPage(14);
					break;
				case 'ZGP1': //Nuevo Pensionado SSV
				case 'ZGP2': //Laudo SSV
				case 'ZGP3': //Desistimiento SSV
				case 'ZGP4': //Pensionados Grupo 4 SSV
				case 'ZG1T': //Nuevo Pensionado Tablet SSV
					break;
				default:
					break;
			}
			return;
		}

		if (response.code === BusquedaCasosResponseCodes.INCONSISTENCIA_DATOS) {
			setAlert({
				show: true,
				severity: 'error',
				message: response.message || getMessageErrorDefault,
			} as IAlert);
		}
	}, [data?.BusquedaCasosResponse]);
	useEffect(() => {
		let response = data?.ConsultaSaldoCeroResponse;
		if (response === undefined) {
			return;
		}
		// Esperando a confirmación
		if (data?.ConsultaDatosDisponibilidadResponse?.code === ConsultaDatosDisponibilidad.RECHAZO_10) {
			setDataException({
				title: response.data?.tipo || 'Error',
				list: response.data?.msg || '',
				question: response.data?.pregunta || '',
				action: response.data?.tipo || '',
			})
			setPage(19);
		} else {
			if (response.code === '0000') {
				if (response.data?.estadoSolicitud === '10') {
					if (response.data?.codigoRechazo === '1' || response.data?.codigoRechazo === '0') {
						setDataException({
							title: response.data?.tipo,
							list: response.data?.msg,
							question: response.data?.pregunta,
							action: response.data?.tipo,
						})
						setPage(19);
					} else {
						setAlert({
							show: true,
							severity: 'error',
							message: response.message || getMessageErrorDefault,
						} as IAlert);
					}
				} else {
					setAlert({
						show: true,
						severity: 'error',
						message: response.message || getMessageErrorDefault,
					} as IAlert);
				}
			} else if (response.code === '2843') {
				setDataException({
					title: response.data?.tipo || 'Error',
					list: response.data?.msg || '',
					question: response.data?.pregunta || '',
					action: response.data?.tipo || '',
				})
				setPage(19);
			} else {
				setDataException({
					title: data?.ConsultaDatosDisponibilidadResponse?.data?.tipo || 'Error',
					list: data?.ConsultaDatosDisponibilidadResponse?.data?.msg || '',
					question: data?.ConsultaDatosDisponibilidadResponse?.data?.pregunta || '',
					action: data?.ConsultaDatosDisponibilidadResponse?.data?.tipo || '',
				})
				setPage(19);
			}
		}

	}, [data?.ConsultaSaldoCeroResponse]);
	useEffect(() => {
		let response =
			data?.ConsultaDatosDisponibilidadContinuaSolicitudResponse;
		if (response === undefined) {
			return;
		}

		if (
			(response.data?.estadoSolicitudFa ===
				DisponibilidadEstatusEnum.NO_PROCEDENTE &&
				response.data?.codRechazoFa ===
				RechazosDisponibilidadEnum.RECHAZO_97) ||
			(response.data?.estadoSolicitudLey73 ===
				DisponibilidadEstatusEnum.NO_PROCEDENTE &&
				response.data?.codRechazoLey73 ===
				RechazosDisponibilidadEnum.RECHAZO_97)
		) {
			handlerReintentoPDFAcuse(data?.caseInformation);
			handleGoPage(8);
		} else {
			let _caseInformation = data?.caseInformation || null;
			setData({
				...data,
				caseInformation: {
					numberCase: _caseInformation?.numberCase || undefined,
					requestDate: _caseInformation?.requestDate || undefined,
					service: _caseInformation?.service || undefined,
					status: _caseInformation?.status || undefined,
					statusDate: _caseInformation?.statusDate || undefined,
					description: _caseInformation?.description || undefined,
					btnCustom: {
						label: 'Continua tu solicitud',
						visible: true,
						onClickOption: 0,
					},
				},
			});
			setDataException({
				title: 'Ocurrio un error',
				list: response.data?.msg,
				question: response.data?.pregunta,
				action: response.data?.tipo,
			})
			setPage(19);
		}
	}, [data?.ConsultaDatosDisponibilidadContinuaSolicitudResponse]);
	useEffect(() => {
		let response = data?.ConsultaDatosDisponibilidadResponse;
		if (response === undefined) {
			return;
		}

		if (response.data?.estadoSolicitudFa === '100' && response.data?.estadoSolicitudLey73 === '100') {
			if (
				response.code === ConsultaDatosDisponibilidad.RECHAZO_10_CAUSAL
				|| response.code === ConsultaDatosDisponibilidad.RECHAZO_20_CAUSAL
				|| response.code === ConsultaDatosDisponibilidad.RECHAZO_47_CAUSAL
				|| response.code === ConsultaDatosDisponibilidad.RECHAZO_53_CAUSAL
				|| response.code === ConsultaDatosDisponibilidad.RECHAZO_90_CAUSAL
				|| response.code === ConsultaDatosDisponibilidad.RECHAZO_COMBINADO
				|| response.code === ConsultaDatosDisponibilidad.RECHAZO_10
			) {
				if (response.data?.ahorroTotal === '0' || response.data?.ahorroTotal === '0.00') {
					handleConsultaSaldoCero();
				} else {
					setDataException({
						title: data?.ConsultaDatosDisponibilidadResponse?.data?.tipo || 'Error',
						list: data?.ConsultaDatosDisponibilidadResponse?.data?.msg || '',
						question: data?.ConsultaDatosDisponibilidadResponse?.data?.pregunta || '',
						action: data?.ConsultaDatosDisponibilidadResponse?.data?.tipo || '',
					})
					setPage(19);
				}
			}
		}

		switch (response.code) {
			case ConsultaDatosDisponibilidad.RECHAZO_97:
				if (
					(response.data?.estadoSolicitudFa ===
						DisponibilidadEstatusEnum.NO_PROCEDENTE &&
						response.data?.codRechazoFa ===
						RechazosDisponibilidadEnum.RECHAZO_97) ||
					(response.data?.estadoSolicitudLey73 ===
						DisponibilidadEstatusEnum.NO_PROCEDENTE &&
						response.data?.codRechazoLey73 ===
						RechazosDisponibilidadEnum.RECHAZO_97)
				) {
					handlerObtieneArchivoAcuse({
						caso: data?.noCaso || '',
					} as IRequestObtieneArchivoAcuse);
				} else {
					let _caseInformation = data?.caseInformation || null;

					setData({
						...data,
						caseInformation: {
							numberCase:
								_caseInformation?.numberCase || undefined,
							requestDate:
								_caseInformation?.requestDate || undefined,
							service: _caseInformation?.service || undefined,
							status: _caseInformation?.status || undefined,
							statusDate:
								_caseInformation?.statusDate || undefined,
							description:
								_caseInformation?.description || undefined,
							btnCustom: {
								label: 'Continua',
								visible: true,
								onClickOption: 0,
							},
						},
					});
				}
				handleGoPage(14);
				break;
			case ConsultaDatosDisponibilidad.ERROR:
				setAlert({
					show: true,
					severity: 'error',
					message: response.message || getMessageErrorDefault,
				} as IAlert);
				break;
			case ConsultaDatosDisponibilidad.EXITO:

				if (response.data?.tipo === 'aclaracion') {
					setDataException({
						title: response.data?.tipo,
						list: response.data?.msg,
						question: response.data?.pregunta,
						action: 'negativa',
					})
					setPage(19);
				} else {
					setData({
						...data,
						grupo: response.data?.grupo || undefined,
						savingsInformationTotal: response.data?.ahorroTotal || '0',
						savingsInformation: {
							subaccount_1997: response.data?.montoSaldo97 || '0',
							subaccount_1992: response.data?.montoSaldo92 || '0',
							savingFund: response.data?.subCuenta || '0',
							additionalQuantity:
								response.data?.cantidadAdicional || '0',
						},
					});
					handleNextPage();
				}
				break;
		}
	}, [data?.ConsultaDatosDisponibilidadResponse]);
	useEffect(() => {
		let response = data?.CreacionCasoResponse;
		if (response === undefined) {
			return;
		}

		if (response.code !== CrearCaso.EXITO) {
			setData({
				...data,
				noCaso: undefined,
				claseOperacion: undefined,
			});
			setAlert({
				show: true,
				severity: 'error',
				message: response.message || getMessageErrorDefault,
			} as IAlert);
			return;
		}
		if (response.code === CrearCaso.EXITO) {
			//Lo que debe hacer en caso de éxito
			let _requestPersisteDatos: IRequestPersisteDatos = {
				opcion: data?.returnOptionType || '',
				grupo: data?.grupo || '',
			};
			switch (page) {
				case 1:
					handleNextPage();
					//handleConsultaDatos();
					break;
				case 4:
					handlebusquedaCasos();
					break;
				case 7: // Tipo de demanda
					handlePersisteOnly(_requestPersisteDatos);
					handleNextPage();
					break;
				case 13: //VerifyBanckDetailsPage
					handlePersisteDatos(_requestPersisteDatos);
					break;
				case 14: //SavingInformation
					handlebusquedaCasos();
					break;
				case 16: //SavingInformation
					handlebusquedaCasos();
					break;
			}
		}
	}, [data?.CreacionCasoResponse]);
	useEffect(() => {
		let response = data?.CuentaDestinoResponse;
		if (response === undefined) {
			return;
		}
		if (response.code !== CuentaDestinoCodes.EXITO) {
			setAlert({
				show: true,
				severity: 'error',
				message: response.message || getMessageErrorDefault,
			} as IAlert);
			return;
		}
		setData({
			...data,
			disableOnlineOption: response.data?.valor === '1' ? false : true,
		});
	}, [data?.CuentaDestinoResponse]);
	useEffect(() => {
		let response = data?.catalogoAforeResponse;
		if (response === undefined) {
			return;
		}
		if (response.code !== catalogoAfore.EXITO) {
			setAlert({
				show: true,
				message: response.message,
				severity: 'error',
			} as IAlert);
		}
	}, [data?.catalogoAforeResponse]);
	useEffect(() => {
		let response = data?.ValidaAforeResponse;
		if (response === undefined) {
			return;
		}
		if (response.code !== ValidaAfore.EXITO) {
			setData({
				...data,
				afore: undefined,
			});
			setAlert({
				show: true,
				message: response.message,
				severity: 'error',
			} as IAlert);
			return;
		}

		handleNextPage();
	}, [data?.ValidaAforeResponse]);
	useEffect(() => {
		let response = data?.ValidaCausaRaizResponse;
		if (response === undefined) {
			return;
		}
		if (response.code !== validaCausaRaiz.EXITO) {
			setData({
				...data,
				certifiedFiles: undefined,
			});
			setAlert({
				show: true,
				message: response.message || getMessageErrorDefault,
				severity: 'error',
			});
			return;
		}
		if (page === 8) {
			if ((data?.BusquedaCasosResponse?.data?.estatus === 'E0024' && data?.claseOperacion === 'ZG1C') || (data?.BusquedaCasosResponse?.data?.estatus === 'E0019' && data?.claseOperacion === 'ZG1D')) {
				//Firmar
				setData({ ...data, runSignature: true });
				if (data?.ReintentoPDFAcuseResponse !== undefined && data?.ReintentoPDFAcuseResponse.code === 'D0000') {
					handleNextPage();
				} else {
					handlerReintentoPDFAcuse(data?.caseInformation);
				}
			} else {
				handleNextPage();
			}
		} else {
			handleNextPage();
		}
	}, [data?.ValidaCausaRaizResponse]);
	useEffect(() => {
		let response = data?.catalogoEntidades;
		if (response === undefined) {
			return;
		}
		if (response.code === catalogoEntidades.EXITO) {
			let array: { value: string; text: string }[] = [];
			response.data?.entidades?.forEach((item) => {
				array.push({
					value: item?.clave || '',
					text: item?.descripcion || '',
				});
			});
			setStates(array);
		}
	}, [data?.catalogoEntidades]);
	useEffect(() => {
		let response = data?.ValidaFechasResponse;
		if (response === undefined) {
			return;
		}

		if (response.code !== ValidaFecha.EXITO) {
			setData({
				...data,
				pensionResolutionDate: undefined,
			});
			setAlert({
				show: true,
				message: response.message || getMessageErrorDefault,
				severity: 'error',
			});
			return;
		}
		//Todo OK

		handleNextPage();
	}, [data?.ValidaFechasResponse]);
	useEffect(() => {
		let response = data?.ValidaPensionResponse;
		if (response === undefined) {
			return;
		}

		if (response.code !== ValidaPension.EXITO) {
			setData({ ...data, typePension: undefined });
			setAlert({
				show: true,
				severity: 'error',
				message: response.message || getMessageErrorDefault,
			} as IAlert);
			return;
		}
		//Todo Ok
		handleNextPage();
	}, [data?.ValidaPensionResponse]);
	useEffect(() => {
		let response = data?.consultaBancoResponse;
		if (response === undefined) {
			return;
		}

		if (response.code !== consultaBanco.EXITO) {
			setData({
				...data,
				bankData: undefined,
			});
			setAlert({
				severity: 'error',
				show: true,
				message: response.message || getMessageErrorDefault,
			} as IAlert);
			return;
		} else if (response.code === consultaBanco.EXITO) {
			handleNextPage();
		}
	}, [data?.consultaBancoResponse]);
	useEffect(() => {
		let response = data?.persisteDatosResponse;
		if (response === undefined) {
			return;
		}
		if (!data?.persisteDatosResponseOnly) {
			if (response.code !== persisteDatos.EXITO && response.code !== persisteDatos.CADUCADO && response.code !== persisteDatos.YA_EXISTE) {
				setAlert({
					show: true,
					severity: 'error',
					message: response.message || getMessageErrorDefault,
				} as IAlert);
				return;
			}
			if (response.code === persisteDatos.EXITO || response.code === persisteDatos.CADUCADO || response.code === persisteDatos.YA_EXISTE) {
				if (data?.grupo === undefined) {
					//handlebusquedaCasos();
					handlebusquedaCasosPersiste();
				} else {
					if ((data?.grupo ? data?.grupo : '') === '1') {
						if (data?.claseOperacion === 'ZAFA') {
							//Te muestra la vista de adjuntar documentos
							handleGoPage(15);
						} else {
							//handlebusquedaCasos();
							handlebusquedaCasosPersiste();
						}
					} else {
						if (
							data.hasDemand === false ||
							data.claseOperacion === 'ZG1D' //Esto es cita?
						) {
							//handlebusquedaCasos(); //ConustaCaso
							handlebusquedaCasosPersiste();
						} else if (
							data.hasDemand === true ||
							data.claseOperacion === 'ZAFA'
						) {
							//Te muestra la vista de adjuntar documentos
							handleGoPage(15);
						}
					}
				}
			}
		}
	}, [data?.persisteDatosResponse]);
	useEffect(() => {
		let response = data?.marcarCuentaResponse;
		if (response === undefined) {
			return;
		}

		if (response.code !== '0000') {
			setAlert({
				show: true,
				message: response.message,
				severity: 'warning',
			});
			return;
		}

		if (response.data?.solicitud === 'SOL0000') {
			handleConsultaDatos();
			return;
		}

		setData({ ...data, startSignDocument: true });
	}, [data?.marcarCuentaResponse]);
	useEffect(() => {
		let response = data?.ObtieneArchivoAcuseResponse;
		if (response === undefined || response.code === '') {
			return;
		}
		handleGoPage(14);
	}, [data?.ObtieneArchivoAcuseResponse]);
	useEffect(() => {
		let response = data?.ReintentoPDFAcuseResponse;
		if (response === undefined) {
			return;
		}
		setData({ ...data, isReintentoSolicitud: true });
		if (page === 14) {
			if (response.code === 'D0000') {
				setAlert({
					show: true,
					message: response.message,
					severity: 'error',
				} as IAlert);

				return;
			}
			handleGoPage(8);
			return;
		}

		if (response.code === 'D0000') {
			//showBtnContinuar("Continua tu solicitud");
			handleNextPage()
		} else {
			//showBtnReintentar('Reintenta tu solicitud');
			if (dataEfirm.keyBinary && dataEfirm.cerBinary && dataEfirm.cerB64 && dataEfirm.password) {
				handleSubmitFirmar(response?.data?.documento);
			} else {
				handleGoPage(8);
			}
		}
		//handleGoPage(14);
	}, [data?.ReintentoPDFAcuseResponse]);
	useEffect(() => {
		let response = data?.AttachDocumentResponse;
		if (response === undefined) {
			return;
		}
		handleConsultaDatos();
	}, [data?.AttachDocumentResponse]);

	return (
		<dataReturnSavingsContext.Provider
			value={{
				MessageToView,
				data,
				setData,
				states,
				page,
				setPage,
				GetPage,
				handleGoHomePage,
				handleGoPage,
				handleGoDate,
				handleNextPage,
				handlePrevPage,
				getMessageErrorDefault,
				alert,
				setAlert,
				loading,
				dataException,
				setDataException,
				setLoading,
				handleConsultaDatos,
				handlebusquedaCasos,
				handleConsultaDatosDisponibilidad,
				handleconsultaDatosDisponibilidadContinuaSolicitud,
				handleConsultaSaldoCero,
				handleCreacionCaso,
				handleCuentaDestino,
				handleGetAfore,
				handleValidaAfore,
				handleValidaCausaRaiz,
				handleGetStates,
				handleValidaFechas,
				handleValidaPension,
				handlerConsultaBanco,
				handlerObtieneArchivoAcuse,
				handlerReintentoPDFAcuse,
				handlerConsultaDocumentacion,
				handlerAttachDocument,
				citaActual,
				setCitaActual,
				doctosRequeridosArray,
				setDoctosRequeridosArray,
				doctosRequeridos,
				setDoctosRequeridos,
				dataEfirm,
				setDataEfirm,
				pdfSigned,
				setPdfSigned,
				optionSelectedItem,
				setOptionSelectedItem,
				handleMarcarCuenta,
				showModalTemporal
			}}
		>
			{children}
		</dataReturnSavingsContext.Provider>
	);
};
