import { Link, Badge } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useStyles } from './styles';
import TooltipCustom from '../../../components/TooltipCustom';
import HelpPanel from '../../HelpPanel';
import { Info } from '@mui/icons-material';
import seleccion from '../../../assets/svg/seleccion.svg';
import expediente from '../../../assets/svg/expediente.svg';
import { ConstantSteps } from '../constantSteps/constantSteps';

export const HipotecaSteps = (data: {
	avanceTaller: number;
	alert: String;
}) => {
	const classes = useStyles();
	const { tallerSaberMas, verificaTusDatos, solicitaTuCredito } =
		ConstantSteps(data);

	return [
		tallerSaberMas,
		verificaTusDatos,
		{
			paperType: 'viewMore',
			paperIcon: expediente,
			title: 'Integra tu expediente',
			description: 'Reúne tus documentos para tramitar tu crédito',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<b>Reúne estos documentos.</b>
						<ul className={classes.viewMoreList}>
							<li>
								Solicitud de inscripción de crédito.{' '}
								<Link
									href={
										'https://portalmx.infonavit.org.mx/wps/wcm/connect/f2b56503-7a4c-44c3-9ef5-075b8325ae9e/Solicitud-inscripcion-credito.pdf?MOD=AJPERES&CVID=nNmSlHE'
									}
									target="_blank"
								>
									Descárgala aquí
								</Link>
							</li>
							<li>
								Acta de nacimiento en original y copia.{' '}
								<Badge
									badgeContent={
										<HelpPanel type="ACTA_NACIMIENTO" />
									}
									className={classes.helpPanel}
								></Badge>
							</li>
							<li>
								Identificación oficial vigente en original y
								copia.{' '}
								<TooltipCustom
									arrow
									placement="right"
									title={
										<>
											<b>
												La identificación oficial puede
												ser:
											</b>
											<ul>
												<li>
													Credencial para votar
													vigente
												</li>
												<li>Pasaporte</li>
											</ul>
										</>
									}
								>
									<Info
										color="primary"
										className={classes.infoStyle}
									/>
								</TooltipCustom>
							</li>
							<li>Copia del CURP.</li>
							<li>Copia de cédula fiscal.</li>
							<li>
								Avalúo de la vivienda. Solicítalo en cualquiera
								de las unidades de valuación con las que trabaja
								el Infonavit.{' '}
								<Link href="/precalificacion-puntos/unidades-valuacion">
									Consulta el directorio
								</Link>
							</li>
							<li>
								<span>
									Carta de instrucción condicionada para
									liquidación y cancelación de hipoteca,
									emitida por la entidad financiera con la que
									tienes la deuda.
								</span>
							</li>
							<li>
								Copia de la Constancia del curso "Saber más para
								decidir mejor" a través de Mi Cuenta Infonavit.
							</li>
						</ul>
						<b>Para crédito conyugal, documentos del cónyuge:</b>
						<ul className={classes.viewMoreList}>
							<li>Acta de matrimonio en original y copia.</li>
							<li>
								Acta de nacimiento original y copia.{' '}
								<Badge
									badgeContent={
										<HelpPanel type="ACTA_NACIMIENTO" />
									}
									className={classes.helpPanel}
								></Badge>
							</li>
							<li>
								Identificación oficial (INE/IFE) o pasaporte
								vigentes, en original y copia.
							</li>
							<li>Copia del CURP.</li>
							<li>Copia de la cédula fiscal.</li>
						</ul>
						<b>
							La carta de instrucción condicionada para
							liquidación y cancelación de hipoteca{' '}
						</b>
						que solicites a la entidad financiera debe tener la
						siguiente información:
						<ul className={classes.viewMoreList}>
							<li>
								Nombre del trabajador (deudor) y RFC con
								homoclave.
							</li>
							<li>Ubicación de la vivienda.</li>
							<li>
								Monto de la deuda proyectada para su
								liquidación.
							</li>
							<li>
								Nombre del notario público que deberá estar
								vigente en el padrón de Notarios Públicos de
								Infonavit.
							</li>
							<li>Nombre de la entidad financiera.</li>
							<li>
								Clave bancaria estandarizada (CLABE) para abono
								en cuenta.
							</li>
							<li>
								Nombre del banco depositario o receptor del
								pago.
							</li>
							<li>
								Vigencia de la carta (asegúrate que esté vigente
								durante todo el proceso de inscripción del
								crédito, de lo contrario será necesario que la
								solicites nuevamente a la Entidad Financiera).
							</li>
						</ul>
					</div>
				</>
			),
		},
		{
			paperType: 'simple',
			paperIcon: seleccion,
			title: 'Selecciona un notario',
			description: (
				<>
					<ul>
						<li>
							Al inscribir tu crédito se te presentará un listado
							de notarios disponibles, escoge uno de ellos.
						</li>
						<li>
							<Link
								href="https://portal.infonavit.org.mx/wps/portal/Infonavit/Servicios/infonavit%20Ampliado/ServicioInex/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziTZyMTJycDB0NDAIsXQw8vUICTEItLAwMjAz0w9EUmLkAFTiFOgYGG3gaW5gb60eRph9VgYEpcfoNcABHA8rsN4Lox2N8uH4UmhWYIYDXDcEmBBSAwoCQKwpyQ0NDIwwyPdMVFQFttxce/dz/d5/L2dBISEvZ0FBIS9nQSEh/#1102000090"
								target="_blank"
							>
								Consulta el directorio de notarios que colaboran
								con el Instituto.
							</Link>
						</li>
						<li>
							No aplica si utilizarás tu Saldo de Subcuenta de
							Vivienda como garantía del pago del crédito. En este
							caso, la formalización de tu crédito la realizarás
							en las oficinas del Infonavit.
						</li>
					</ul>
				</>
			),
			link: (
				<a
					rel="noreferrer"
					className={classes.optionEnlace}
					target="_blank"
					href="https://portal.infonavit.org.mx/wps/portal/Infonavit/Servicios/infonavit%20Ampliado/ServicioInex/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziTZyMTJycDB0NDAIsXQw8vUICTEItLAwMjAz0w9EUmLkAFTiFOgYGG3gaW5gb60eRph9VgYEpcfoNcABHA8rsN4Lox2N8uH4UmhWYIYDXDcEmBBSAwoCQKwpyQ0NDIwwyPdMVFQFttxce/dz/d5/L2dBISEvZ0FBIS9nQSEh/#1102000090"
				>
					Consulta el directorio <ChevronRightIcon />{' '}
				</a>
			),
		},
		solicitaTuCredito,
	];
};
