import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ICredito } from '../../interfaces/SaldAndMovement';
import { apiSaldAndMovements } from '../../api/modules/SaldAndMovements';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import ModalLoading from '../../components/ModalLoading';
import Paperless from './Paperless';
import Layout from '../../components/Layout';
import SaldAndMovement from './SaldAndMovement';
import MyCreditData from './MyCreditData';
import MovementsAccount from './MovementsAccount';
import PaymentOptions from './PaymentOptions';
import NoFoundServices from './NoFoundServices';
import ClarificationPayments from './ClarificationPayments';

const SaldAndMovements = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [view, setView] = useState(0);
	const [credito, setCredito] = useState<ICredito>({});
	const [loagin, setLoagin] = useState(false);
	const [paperless, setPaperless] = useState(false);
	const [modalPaperless, setModalPaperless] = useState(false);
	const [showBtnCalidad, setShowBtnCalidad] = useState('');

	const no_credito: string = credit;

	useEffect(() => {
		getBalance();
		getParperless();
		getCertificadoCalidad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, no_credito]);

	const getBalance = async () => {
		try {
			setLoagin(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credit_encript = await encrypt(no_credito || '', key);
			const { result } = await apiSaldAndMovements.getBalance(
				credit_encript
			);
			if (typeof result !== 'string') {
				result.derechohabiente = user?.given_name
					? user?.given_name
					: '';
				result.nss = user?.nss;
				setCredito(result);
			} else {
				setView(-1);
			}
		} catch (error) {
			setView(-1);
		} finally {
			setLoagin(false);
		}
	};

	const getParperless = async () => {
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credit_encript = await encrypt(no_credito || '', key);
			const { status } = await apiSaldAndMovements.getPapperlessStatus(
				credit_encript
			);
			setPaperless(!status);
			setModalPaperless(!status);
		} catch (error) { }
	};

	const AceptPaperless = async () => {
		try {
			setLoagin(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credit_encript = await encrypt(no_credito || '', key);
			await apiSaldAndMovements.setPapperlessStatus(credit_encript);
			setPaperless(false);
			setModalPaperless(false);
		} catch (error) {
		} finally {
			setLoagin(false);
		}
	};

	const getCertificadoCalidad = async () => {
		try {
			setLoagin(true);
			setShowBtnCalidad('');
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const credit_encript = await encrypt(no_credito, key);
			const { result } = await apiSaldAndMovements.getCertificadoCalidad(
				credit_encript
			);
			if (result.codigo === '00') {
				setShowBtnCalidad(result.urlPoliza);
			}
		} catch (error) {
			setShowBtnCalidad('');
		} finally {
			setLoagin(false);
		}
	};

	return (
		<Layout>
			<div style={{ marginBottom: 50, fontSize: 15 }}>
				<ModalLoading loading={loagin} />

				<Paperless
					showModal={modalPaperless}
					setShowModal={setModalPaperless}
					setAccept={AceptPaperless}
				/>

				{view === -1 && <NoFoundServices />}
				{view === 0 && (
					<SaldAndMovement setView={setView} credito={credito} />
				)}
				{view === 1 && (
					<MyCreditData setView={setView} credito={credito} showBtnCalidad={showBtnCalidad} />
				)}
				{view === 2 && (
					<MovementsAccount
						setView={setView}
						credito={credito}
						paperless={paperless}
						AceptPaperless={AceptPaperless}
					/>
				)}
				{view === 3 && (
					<PaymentOptions
						credit={credito}
						user={user}
						setView={setView}
						onlyCredit={no_credito}
					/>
				)}
				{view === 4 && <ClarificationPayments credito={credito} />}
			</div>
		</Layout>
	);
};

export default SaldAndMovements;
