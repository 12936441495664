/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomButton from '../../components/Button';
import CustomAlert from '../../components/CustomAlert';
import { useStyles } from './style';
import Link from '@mui/material/Link';
import FormAmounts from './FormAmounts';
import { currencyFormat } from '../../utils/expressions';

interface Props {
	convenios: any;
	reestructuras: any;
	typeSelected: any;
	onNextPage: () => void;
	onPrevPage: () => void;
	FactorDcp: (data: any) => void;
	alertGral: any;
	factorPagos: any;
}

const Product = ({ convenios, onNextPage, reestructuras, typeSelected, onPrevPage, FactorDcp, alertGral, factorPagos }: Props) => {
	const columnas = [
		'Sección ' + typeSelected,
		'VSM',
		'Pesos',
		'VSM',
		'Pesos',
		'VSM',
		'Pesos',
	];
	const [titleContent, setTitleContent] = useState({
		title: '',
		messages: ['']
	})
	const [values, setValues] = useState({} as any)

	const calculateFactor = (data: any) => {
		FactorDcp(data)
	}

	useEffect(() => {
		let filtrado: any[] = [];
		filtrado = convenios.filter((e: any) => e.producto === typeSelected);
		if (typeSelected === 'STM') {
			setTitleContent({
				title: 'Solución a Tu Medida (STM)',
				messages: [
					`Solución a tu Medida. Con base en lo que debes y tu capacidad de pago, 
					el instituto fija la cantidad que pagarás durante 12 meses. Los intereses 
					ordinarios se sumarán a lo que debes de tu crédito y los moratorios se 
					condonarán. Si eres cumplido con los pagos, podrás renovarlo de acuerdo con 
					lo establecido en las normas y políticas vigentes.`
				]
			})
			setValues(filtrado[0])
		} else if (typeSelected === 'DCP') {
			setTitleContent({
				title: 'Dictamen de Capacidad de Pago Privado (DCP)',
				messages: [
					`Ofrecer un convenio de reestructura del factor de pago acorde 
				 	a la capacidad de pago de los o las acreditados(as) que presentan 
					problemas económicos y que requieran apoyo para continuar con el pago de su crédito`]
			})
			setValues(filtrado[0])
		} else if (typeSelected === 'BCP') {
			setTitleContent({
				title: 'Borrón y Cuenta Nueva por Convenio Privado (BCP)',
				messages: [
					`Ofrecer a las personas acreditadas una alternativa de regularización 
					en aquellos créditos que tengan pendiente de 1 a 9 mensualidades, 
					manteniendo el mismo factor de pago antes de aplicar a esta reestructura.`,
					`Al dar clic en CONTINUAR, podrás descargar el Convenio y revisar si 
					tu crédito es elegible a recibir un beneficio económico y las condiciones 
					para recibirlo en la cláusula “SEGUNDA. CAPITALIZACIÓN Y BENEFICIO ECONÓMICO”. 
					Si en la cláusula segunda de tu convenio, el título no incluye la sección 
					BENEFICIO ECONÓMICO, no eres elegible a recibirlo.`
				]
			})
			setValues(filtrado[0])
		} else if (typeSelected === 'BCN') {
			setTitleContent({
				title: 'Borrón y Cuenta Nueva (BCN)',
				messages: [
					`Ofrecer a los acreditados(as) una alternativa de regularización 
					que abarque todo los créditos con morosidad de 1 a 24 omisiones en ROA
					y REA, manteniendo el factor de pago contratado antes de la reestructura
					y capitalizando los intereses generados`
				]
			})
			setValues(filtrado[0])
		}
	}, [typeSelected, convenios, reestructuras])

	const style = useStyles();
	return (
		<>
			<p className={style.bold_Center}>
				{titleContent.title}
			</p>
			{titleContent.messages && titleContent.messages.map((item: any, index: any) => (
				<p className={style.textAlign_Center} style={{ padding: '0 60px' }} key={'textos' + index}>
					{item}
				</p>
			))}

			{typeSelected === 'BCP' && (
				<p className={style.textAlign_Center} style={{ padding: '0 60px' }}>
					Para más información sobre esta reestructura da clic{' '}
					<Link href="https://portalmx.infonavit.org.mx/wps/portal/infonavitmx/mx2/derechohabientes/tengo-un-credito/soluciones_para_ti/!ut/p/z1/lZFLD4IwEIR_Uqc8tuVIICgSC43KoxfDyTRR9GD8_WLCxRBee9vsN8nMDjOsZqZrP_bWvu2za-_93hi60g7YRx5XuUgi6MSn0xEFZEas-gdkKUNoFSgnI9c5hMTM5DnNz-6gD4LY45mEU3AF6FLzOI0FfHjr9JiYEOv0kwHFRv0YMJv-NwbMfLyKmSWLc0BfkVgCaADmSlqy-XpcflPDpvYL7dw_tg!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/" target="blank">
						aquí.
					</Link>
				</p>
			)}

			<div style={{ margin: '50px 0 30px' }}>
				{typeSelected === 'DCP' && (
					<FormAmounts
						callEndPoint={calculateFactor}
					/>
				)}
			</div>

			<Box className={style.table1}>
				<div>
					<div className="row" style={{ display: 'flex', flexDirection: 'row', margin: '30px 0' }}>
						<div style={{ width: '14.28%' }}></div>
						<div className={style.tabla2TitulosDiv}>
							<div className={style.tabla2Titulos}>
								<p className={style.textGrayBox}>
									Condiciones actuales de tu crédito
								</p>
							</div>
						</div>
						<div className={style.tabla2TitulosDiv}>
							<div className={style.tabla2Titulos}>
								<p className={style.textGrayBox}>
									Condiciones si seleccionas <br />el apoyo de
									reestructura
								</p>
							</div>
						</div>
						<div className={style.tabla2TitulosDiv}>
							<div className={style.tabla2Titulos}>
								<p className={style.textGrayBox}>
									Diferencia entre las condiciones <br />actuales y
									con el apoyo de reestructura
								</p>
							</div>
						</div>
					</div>

					{/* Encabezados */}
					<div className="row"
						style={{
							display: 'flex',
							flexDirection: 'row',
							borderBottom: '1px solid #e1e1e1',
							padding: '10px 0',
							marginBottom: '5px',
						}}
					>
						{columnas.map((col, index) => (
							<div key={'encabezado' + index} className={style.tabla2Encabezados}>{col}</div>
						))}
					</div>

					{/* REA */}
					<div className="row"
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							borderBottom: '1px solid #e1e1e1',
							padding: '10px 0',
						}}
					>
						<div className={style.tabla2Datos} style={{ textAlign: 'left', paddingLeft: '20px', paddingRight: 0 }}>
							Si pagas por tu cuenta (REA)
						</div>
						<div className={style.tabla2Datos}>
							{typeSelected === 'BCP' ? values.factorReaVsm : reestructuras.ctFacRea}
						</div>
						<div className={style.tabla2Datos}>
							${currencyFormat(typeSelected === 'BCP' ? values.factorReaPesos : reestructuras.ctFacReaPes)}
						</div>
						<div className={style.tabla2Datos}>
							{values.factorReaVsm}
						</div>
						<div className={style.tabla2Datos}>
							${currencyFormat(values.factorReaPesos)}
						</div>
						<div className={style.tabla2Datos}>
							{typeSelected === 'BCP' ? '0.000' : values.difReaVsm}
						</div>
						<div className={style.tabla2Datos}>
							${currencyFormat(typeSelected === 'BCP' ? '0.000' : values.difReaPesos)}
						</div>
					</div>
					{/* ROA */}
					<div className="row"
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							borderBottom: '1px solid #e1e1e1',
							padding: '10px 0',
						}}
					>
						<div className={style.tabla2Datos} style={{ textAlign: 'left', paddingLeft: '20px', paddingRight: 0 }}>
							Si el descuento es por nómina (ROA)
						</div>
						<div className={style.tabla2Datos}>
							{typeSelected === 'BCP' ? values.factorRoaVsm : reestructuras.ctFacRoa}
						</div>
						<div className={style.tabla2Datos}>
							${currencyFormat(typeSelected === 'BCP' ? values.factorRoaPesos : reestructuras.ctFacRoaPes)}
						</div>
						<div className={style.tabla2Datos}>
							{values.factorRoaVsm}
						</div>
						<div className={style.tabla2Datos}>
							${currencyFormat(values.factorRoaPesos)}
						</div>
						<div className={style.tabla2Datos}>
							{typeSelected === 'BCP' ? '0.000' : values.difRoaVsm}
						</div>
						<div className={style.tabla2Datos}>
							${currencyFormat(typeSelected === 'BCP' ? '0.000' : values.difRoaPesos)}
						</div>
					</div>

				</div>
			</Box>

			<Typography
				sx={{
					fontWeight: 'bold',
					fontSize: 16.5,
					pt: 5,
					mb: 3,
				}}
				align="center"
			>
				Los montos aquí presentados NO consideran el costo de los accesorios
				y seguros. NO olvides agregarlos al momento de realizar tu pago.
				Para conocer el monto total de tu mensualidad, debes revisar en el
				menú Mi crédito, y seleccionar la opción Saldos y Movimientos.
			</Typography>

			<Box className={style.BoxAlert}>
				<CustomAlert
					message={alertGral?.message}
					severity={alertGral?.severity}
					show={alertGral?.show}
				/>
			</Box>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '540px',
						paddingTop: '30px',
						paddingBottom: '50px',
					}}
				>
					<Box className={style.BoxButton}>
						<CustomButton
							onClick={onPrevPage}
							variant="outlined"
							label="Regresar"
							styles={{ height: '100%' }}
						/>
					</Box>
					<Box className={style.BoxButton}>
						<CustomButton
							onClick={() => {
								onNextPage()
							}}
							variant="solid"
							label="Continuar"
							disabled={typeSelected === 'DCP' && !factorPagos.show}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default Product;
