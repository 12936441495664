import { Link } from '@mui/material';
import { useStyles } from './styles';
import { RoundBtn } from '../RoundBtn/roundBtn';
import { useNavigate } from 'react-router-dom';
import { useGeneralStyles } from '../../views/styles/general.styles';

interface IProps {
	setFlag: any;
}

export const BuroMssg = (props: IProps) => {
    const {setFlag} = props;
    const classes = useStyles();
    const navigate = useNavigate();
    const generalStyle = useGeneralStyles();
	return (
		<>
			<p className={classes.mainInfo}>
				En este momento, debido a tu historial crediticio, no podemos
				evaluar tus condiciones financieras, te sugerimos obtener tu
				reporte de crédito especial en{' '}
				<Link href="https://www.burodecredito.com.mx/" target="_blank" color={'#293990'}>
					Buró de Crédito
				</Link>{' '}
				en la sección "Consumidor" para conocer el detalle. Sin
				embargo, Infonavit te ofrece alternativas, que puedes conocer
				seleccionando a continuación:{' '}
				<Link onClick={() => setFlag(false)} color={'#293990'}>
					"No autorizo la consulta a mi historial crediticio"
				</Link>
				, de esta manera te aparecerán las opciones que tenemos para ti.
			</p>
            <div className={generalStyle.bottonsContainer}>
				<RoundBtn variant="outlined" onClick={() => navigate('/')}>
					Salir
				</RoundBtn>
			</div>
		</>
	);
};
