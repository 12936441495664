export type typeType =
	| 'VINCULA_CONYUGE'
	| 'ACTA_NACIMIENTO'
	| 'CERTIFICADO_DISCAPACIDAD'
	| 'TERRENO'
	| 'INFORMACION_COMPLEMENTOS'
	| 'CARTA_INSTRUCCION'
	| 'APOYO_INFONAVIT'
	| 'ECOTECNOLOGIAS'
	| 'ELEGIR_VIVIENDA'
	| 'GESTION_AVALUO'
	| 'CONSTRUYO_RM'
	| 'CONSTRUYO_AT'
	| 'CONSTRUYO_CN'
	| 'PLAZO_ESTIMADO'
	| 'ENTIDADES'
	| 'AUTORIZACION_BC_CONYUGE'
	| 'CARACTERISTICAS_RM'
	| 'CARACTERISTICAS_AT'
	| 'CARACTERISTICAS_CN'
	| 'AVIABLE_CREDITS'
	| 'NOTARIO_SELECT'
	| 'DEBES_CONOCER'
	| 'EQUIPA_TU_CASA'
	| 'COMPROBANTES_AUTORIZADOS';

export const typesPanels: { [key: string]: string } = {
	VINCULA_CONYUGE: 'VINCULA_CONYUGE',
	ACTA_NACIMIENTO: 'ACTA_NACIMIENTO',
	CERTIFICADO_DISCAPACIDAD: 'CERTIFICADO_DISCAPACIDAD',
	TERRENO: 'TERRENO',
	INFORMACION_COMPLEMENTOS: 'INFORMACION_COMPLEMENTOS',
	CARTA_INSTRUCCION: 'CARTA_INSTRUCCION',
	APOYO_INFONAVIT: 'APOYO_INFONAVIT',
	ECOTECNOLOGIAS: 'ECOTECNOLOGIAS',
	ELEGIR_VIVIENDA: 'ELEGIR_VIVIENDA',
	GESTION_AVALUO: 'GESTION_AVALUO',
	CONSTRUYO_RM: 'CONSTRUYO_RM',
	CONSTRUYO_AT: 'CONSTRUYO_AT',
	CONSTRUYO_CN: 'CONSTRUYO_CN',
	PLAZO_ESTIMADO: 'PLAZO_ESTIMADO',
	ENTIDADES: 'ENTIDADES',
	AUTORIZACION_BC_CONYUGE: 'AUTORIZACION_BC_CONYUGE',
	CARACTERISTICAS_RM: 'CARACTERISTICAS_RM',
	CARACTERISTICAS_AT: 'CARACTERISTICAS_AT',
	CARACTERISTICAS_CN: 'CARACTERISTICAS_CN',
	AVIABLE_CREDITS: 'AVIABLE_CREDITS',
	NOTARIO_SELECT: 'NOTARIO_SELECT',
	DEBES_CONOCER: 'DEBES_CONOCER',
	EQUIPA_TU_CASA: 'EQUIPA_TU_CASA',
	COMPROBANTES_AUTORIZADOS: 'COMPROBANTES_AUTORIZADOS',
};

export const typesSizes: { [key: string]: any } = {
	VINCULA_CONYUGE: 'sm',
	CARTA_INSTRUCCION: 'sm',
	ECOTECNOLOGIAS: 'md',
	AUTORIZACION_BC_CONYUGE: 'sm',
	CERTIFICADO_DISCAPACIDAD: 'sm',
	TERRENO: 'sm',
	APOYO_INFONAVIT: 'sm',
	PLAZO_ESTIMADO: 'md',
};

export const typesTitles: { [key: string]: string } = {
	VINCULA_CONYUGE: 'Asocia el NSS de tu cónyuge, familiar o corresidente.',
	ACTA_NACIMIENTO: 'Acta de nacimiento',
	CERTIFICADO_DISCAPACIDAD: 'Certificado de discapacidad',
	INFORMACION_COMPLEMENTOS: 'Complementos',
	TERRENO: 'Pon el terreno a tu nombre',
	CARTA_INSTRUCCION: 'Carta de instrucción',
	APOYO_INFONAVIT: 'Entidades participantes con Apoyo Infonavit',
	ECOTECNOLOGIAS: 'Monto de crédito para ecotecnologías',
	AUTORIZACION_BC_CONYUGE:
		'Para obtener un mayor monto de crédito de tu cónyuge, bastará con que autorice la consulta a su historial crediticio',
	ELEGIR_VIVIENDA: 'Elegir vivienda',
	GESTION_AVALUO: 'Solicitud de avalúo',
	CONSTRUYO_RM: 'ConstruYo Reparaciones Menores',
	CONSTRUYO_AT: 'ConstruYo con Asistencia Técnica',
	CONSTRUYO_CN: 'ConstruYo con Constructora',
	CARACTERISTICAS_RM: 'ConstruYo Reparaciones menores',
	CARACTERISTICAS_AT: 'ConstruYo con Asistencia Técnica',
	CARACTERISTICAS_CN: 'ConstruYo con Constructora',
	PLAZO_ESTIMADO: 'Lo que debes saber del plazo estimado:',
	ENTIDADES: 'Entidades financieras participantes',
	NOTARIO_SELECT: 'Al elegir notario, toma en cuenta lo siguiente:',
	DEBES_CONOCER: 'Información que debes conocer',
	EQUIPA_TU_CASA: 'Equipa tu casa',
	COMPROBANTES_AUTORIZADOS: 'Comprobantes autorizados',
};

export const typesContruir: { [key: string]: string } = {
  RM: 'RM',
	AT: 'AT',
	CN: 'CN',
  CONSTRUYO_RM: 'RM',
	CONSTRUYO_AT: 'AT',
	CONSTRUYO_CN: 'CN',
  CARACTERISTICAS_RM: 'RM',
	CARACTERISTICAS_AT: 'AT',
	CARACTERISTICAS_CN: 'CN',
};
