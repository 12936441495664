import { Link } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useStyles } from './styles';
import TooltipCustom from '../../../components/TooltipCustom';
import { Info } from '@mui/icons-material';
import avaluo from '../../../assets/svg/avaluo.svg';
import expediente from '../../../assets/svg/expediente.svg';
import { ConstantSteps } from '../constantSteps/constantSteps';

export const CrediterrenoSteps = (data: {
	avanceTaller: number;
	alert: String;
}) => {
	const classes = useStyles();
	const {
		tallerSaberMas,
		verificaTusDatos,
		notarioTitulacion,
		solicitaTuCredito,
	} = ConstantSteps(data);

	return [
		tallerSaberMas,
		verificaTusDatos,
		{
			paperType: 'viewMore',
			paperIcon: avaluo,
			title: 'Gestión de avalúo',
			description:
				'Solicita tu avalúo en cualquiera de las Unidades de Valuación.',
			viewMoreLink: (
				<a
					href="/precalificacion-puntos/unidades-valuacion"
					className={classes.optionEnlace}
				>
					{' '}
					Consulta el directorio <ChevronRightIcon />
				</a>
			),
			viewMoreButton: 'Consulta los requisitos',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<ul className={classes.viewMoreList}>
							<li>
								Solicita el Estudio de valor y dictamen técnico
								del terreno con cualquiera de las{' '}
								<Link
									href="https://portalmx.infonavit.org.mx/wps/portal/infonavit.web/proveedores-externos/para-tu-gestion/unidades-valuacion/consulta_unidades_valuacion/!ut/p/z1/pZDLCsIwEEU_KbeJDnGZSE3rM4WG1mykKwlodSF-v6G7KrYV727gnJnhMs9q5tvmGc7NI9za5hLno6cTGSBbzpI9dlKjEHwzt8oiKwWr-oBZg6M46NI6rgURMf-Hb8uJPpeA0Yi-zCTUKs3TxVZw45JpPr5E4cf_PwE_vL5ivn_irUFLIwCNAF2HHTBU0tib96uLqRHyoF5Xp7Gw/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
									target="_blank"
								>
									Unidades de Valuación
								</Link>{' '}
								con las que trabaja el Infonavit.
							</li>
							<li>
								Una vez que hayas elegido la unidad de
								valuación, prepara la siguiente documentación:
								<ul className={classes.viewMoreList}>
									<li>Escritura antecedente de propiedad</li>
									<li>
										Constancia de Alineamiento y número
										oficial.
									</li>
									<li>
										Documento oficial que acredite que el de
										uso de suelo es habitacional o mixto que
										incluya uso habitacional.
									</li>
									<li>Boleta predial (última)</li>
									<li>Boleta de agua (última)</li>
									<li>
										Plano o croquis del inmueble (Si no
										cuentas con él, pueden hacértelo con un
										costo adicional)
									</li>
									<li>
										Copia de la Identificación oficial del
										vendedor y del comprador
									</li>
								</ul>
							</li>
							<li>
								Adicionalmente te pedirán llenar una Solicitud
								de avalúo.{' '}
								<Link
									href={require('../../../../../assets/files/guia-pasos-a-seguir/SOLICITUD_DE_AVALUO_INMOBILIARIO_Y_DICTAMEN_TECNICO_DE_CALIDAD.pdf')}
									target="_blank"
								>
									Descárgala aquí.
								</Link>{' '}
								Comunícate y haz una cita.
							</li>
							<li>
								Contacta a la unidad de valuación que elegiste,
								te solicitarán domicilio completo del inmueble y
								acordarán la visita.
							</li>
							<li>
								Indícale que se trata de un trámite de crédito
								con Infonavit pues se tienen tarifas
								preferenciales.
							</li>
						</ul>
					</div>
				</>
			),
		},
		{
			paperType: 'viewMore',
			paperIcon: expediente,
			title: 'Integra tu expediente',
			description: 'Reúne tus documentos para tramitar tu crédito',
			viewMoreButton: 'Consulta la documentación',
			viewMoreContent: (
				<>
					<div className={classes.viewMoreContent}>
						<b>Reúne estos documentos</b>
						<ul className={classes.viewMoreList}>
							<li>
								Solicitud de inscripción de crédito.{' '}
								<Link
									href={
										'https://portalmx.infonavit.org.mx/wps/wcm/connect/f2b56503-7a4c-44c3-9ef5-075b8325ae9e/SolicituddeInscripciondeCredito_CRED.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=ozkrCXH'
									}
									target="_blank"
								>
									Descárgala aquí.
								</Link>
							</li>
							<li>
								Acta de nacimiento en original y copia. Ten en
								cuenta que, si tu acta de nacimiento es digital,
								debes traerla impresa.
							</li>
							<li>
								Identificación oficial vigente en original y
								copia.{' '}
								<TooltipCustom
									arrow
									placement="right"
									title={
										<>
											<b>
												La identificación oficial puede
												ser:
											</b>
											<ul>
												<li>
													Credencial para votar
													vigente
												</li>
												<li>Pasaporte</li>
											</ul>
										</>
									}
								>
									<Info
										color="primary"
										className={classes.infoStyle}
									/>
								</TooltipCustom>
							</li>
							<li>
								Comprobante de domicilio con una antigüedad
								máxima de 3 meses y estar pagado.
							</li>
							<li>
								<Link
									href={require('../../../../../assets/files/guia-pasos-a-seguir/CartaBajoProtestadeDecirVerdad.pdf')}
									target="_blank"
								>
									Carta bajo protesta de decir verdad
								</Link>
							</li>
							<li>
								Estado de cuenta bancario del vendedor con Clave
								Interbancaria Estandarizada (CLABE).
							</li>
							<li>
								Estudio de valor y dictamen técnico del terreno.
							</li>
							<li>
								Copia de la constancia del curso Saber más para
								decidir mejor
							</li>
						</ul>
					</div>
				</>
			),
		},
		notarioTitulacion,
		solicitaTuCredito,
	];
};
