/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-self-compare */
import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { keyAPI } from '../../api/modules/key';
import { restructuringRequestAPI } from '../../api/modules/RestructuringRequest';
import { HeaderList } from '../../components/HeaderList';
import Layout from '../../components/Layout';
import ModalLoading from '../../components/ModalLoading';
import { IAlert } from '../../interfaces/alert';
import { IOnValuesRestructuringRequest, IResponseTypesRejection } from '../../interfaces/RestructuringRequest';
import { RootState } from '../../store';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { encrypt } from '../../utils/encrypt';
import Resumable from 'resumablejs';
import moment from 'moment';
import 'moment/locale/es';

import Home from './Home';
import SelectTypes from './SelectTypes';
import Product from './Product';
import Summary from './Summary';
import Status from './Status';
import FormRejection from './FormRejection';

import { useStyles } from './style';
import { useBitacora } from '../../hooks/useBitacora';

const RestructuringRequest = () => {
    const { regBitacoraNotOld } = useBitacora();
	const [suceptible, setSuceptible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const { user } = useSelector((state: RootState) => state.session);
	const { credit } = useSelector((state: RootState) => state.credit);
	const [view, setView] = useState(0);
	const [data, setData] = useState<IOnValuesRestructuringRequest>({
		ButtonEnable: false,
		view: '',
	} as IOnValuesRestructuringRequest);

	//new States
	const [fileDowloaded, setFileDowloaded] = useState({
		payload: '',
		name: '',
		valid: false
	})
	const [dataCase, setDataCase] = useState({} as any)
	const [convenios, setConvenios] = useState([] as any)
	const [reestructura, setReestructura] = useState({} as any)
	const [typeSelected, setTypeSelected] = useState('')
	const [amounts, setAmount] = useState({
		ingresos: 0,
		egresos: 0
	})
	const [factorPagos, setFactorPagos] = useState({
		factorPago: 0,
		factorPagoDiario: 0,
		factorPagoMensual: 0,
		show: false,
		creditoPesos: ''
	})
	const [dataRejection, setDataRejection] = useState([] as any)
	const [showModalRechazo, setShowModalRechazo] = useState(false)
	const paramsResumable = {
		usuario: 'ZV54ZnTZ0+d7wF7R3je0tryJ94iCn588Z552h7QQyJs=',
		password: '2dx2Ew6+niIblGDfP4c8NMUtGmKnjmm+OO+/Qs8307s='
	}
	const [dataToWait, setDataToWait] = useState({
		wait: false,
		dateToShow: '',
	} as any)

	//Nueva adaptación
	useEffect(() => {
		FindCases();
		if (localStorage.getItem('expireConvenio')) {
			setDataToWait({
				wait: true,
				dateToShow: localStorage.getItem('expireConvenio')
			})
		}
	}, []);

	useEffect(() => {
		if (dataToWait.dateToShow) {
			const intervalTime = setInterval(() => {
				checkConvenio();
			}, 1000);
			return () => clearInterval(intervalTime);
		}
	}, [dataToWait]);

	const checkConvenio = () => {
		let diferencetime = (new Date(dataToWait.dateToShow).getTime() - new Date().getTime()) / 1000;
		if (!diferencetime || diferencetime < 0) {
			setDataToWait({
				wait: false,
				dateToShow: ''
			})
			localStorage.removeItem('expireConvenio');
			FindCases();
		}
	};

	const FindCases = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(user?.nss.toString() || '', key);
			restructuringRequestAPI
				.FindCases({
					nss: nss_base64,
				})
				.then((res) => {
					if (res.result.code === '0000') {
						if (res.result.data.caso.estatus === 'E0004') {
							initNewCase();
						} else {
							setDataCase(res.result.data.caso);
							if (res.result.data.puedeDescargar) {
								ConsulDoc(res.result.data.caso.folio);
							} else {
								setLoading(false);
							}
							onChangePage(4);
						}
					} else if (res.result.code === '0001') {
						AvailabilityAgreements();
					} else {
						setAlert({
							show: true,
							message: res.result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
						setLoading(false);
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
					setLoading(false);
				})
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};
	const AvailabilityAgreements = async () => {
		setLoading(true);
		try {
			setSuceptible(false);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				credito: credit,
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			setLoading(true);
			restructuringRequestAPI
				.AvailabilityAgreements(dataSendEncrypt)
				.then((res) => {
					if (res.result.code === '0000') {
						setSuceptible(true);
						setReestructura(res.result.data.reestructura)
						setConvenios(res.result.data.reestructura.convenios)
					} else if (res.result.code === '0002') {
						setAlert({
							show: true,
							message: res.result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'warning',
						});
					} else {
						setAlert({
							show: true,
							message: res.result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};
	const ConsulDoc = async (folio: any) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				folio: folio,
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			restructuringRequestAPI
				.ConsulDoc(dataSendEncrypt)
				.then((res) => {
					if (res?.result?.code === '0000') {
						setFileDowloaded({
							payload: res?.result?.data?.documentos?.archivo,
							name: `Reestructura-${dataCase?.noCaso}`,
							valid: true
						});
					}
				})
				.catch((error: any) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};
	const CreateCase = async (pdf: any, tipoOperacion: any, beneficio: string) => {
		setLoading(true);
		try {
			let roaPesos, roaVsm, reaPesos, reaVsm;
			let filtrado: any[] = [];
			filtrado = convenios.filter((e: any) => e.producto === typeSelected);
			if (filtrado.length > 0) {
				roaPesos = filtrado[0].factorRoaPesos
				roaVsm = filtrado[0].factorRoaVsm
				reaPesos = filtrado[0].factorReaPesos
				reaVsm = filtrado[0].factorReaVsm
			}
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				nss: user?.nss,
				credito: credit,
				tipoEstructura: typeSelected,
				roaVsm: roaVsm,
				roaPesos: roaPesos,
				reaVsm: reaVsm,
				reaPesos: reaPesos,
				ingresos: amounts.ingresos,
				egresos: amounts.egresos,
				beneficio: beneficio || '',
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			restructuringRequestAPI
				.CreateCaseRequest(dataSendEncrypt)
				.then((res) => {
					if (res?.result?.code === '0000') {
                        regBitacoraNotOld(restructuringRequestAPI.schemeUrl, paramsData);
						setDataCase(res.result.data.caso);
						firmarConvenio(pdf, res.result.data.caso.noCaso, tipoOperacion);
					} else {
						setAlert({
							show: true,
							message: res.result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
						setLoading(false);
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
					setLoading(false);
				})
				.finally(() => { });
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};
	const firmarConvenio = async (pdf: any, caso: any, type: any) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				nss: user?.nss,
				credito: credit || '',
				pdfBase64: pdf,
				producto: typeSelected
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			restructuringRequestAPI
				.firmarConvenio(dataSendEncrypt)
				.then((res) => {
					if (res?.result?.code === '0000') {
						const byteArray = window.atob(res.result.data.pdfFirmadoBase64).split('').map(char => char.charCodeAt(0));
						const blob = new Blob([new Uint8Array(byteArray)], { type: "application/pdf" });
						const file = new File([blob], `${caso}_Convenio.pdf`, { type: "application/pdf" });
						resumableFile.addFile(file);
						setTimeout(() => {
							encolarConvenio(caso, type)
						}, 3000);
					} else {
						setAlert({
							show: true,
							message: res.result.message || INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	}
	const RechazoCaseRequest = async (motivo: any) => {
		setLoading(true);
		try {
			let roaPesos, roaVsm, reaPesos, reaVsm;
			let filtrado: any[] = [];
			filtrado = convenios.filter((e: any) => e.producto === typeSelected);
			if (filtrado.length > 0) {
				roaPesos = filtrado[0].factorRoaPesos
				roaVsm = filtrado[0].factorRoaVsm
				reaPesos = filtrado[0].factorReaPesos
				reaVsm = filtrado[0].factorReaVsm
			}
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				nss: user?.nss,
				credito: credit,
				tipoEstructura: typeSelected,
				roaVsm: roaVsm,
				roaPesos: roaPesos,
				reaVsm: reaVsm,
				reaPesos: reaPesos,
				ingresos: amounts.ingresos,
				egresos: amounts.egresos,
				tipoRechazo: motivo
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);

			restructuringRequestAPI
				.RechazoCaseRequest(dataSendEncrypt)
				.then((res) => {
                    regBitacoraNotOld(restructuringRequestAPI.schemeUrl, paramsData);
					setShowModalRechazo(true)
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error?.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};
	const TypesRejection = async () => {
		setLoading(true);
		try {
			restructuringRequestAPI
				.TypesRejection()
				.then((res) => {
					let RejectElements: IResponseTypesRejection[] = [];
					let key: string[] = Object.keys(res.result.data.motivosRechazo);
					for (let index = 0; index < key.length; index++) {
						let element: IResponseTypesRejection;
						element = {
							value: key[index],
							label: res.result.data.motivosRechazo[key[index]],
						};
						RejectElements.push(element);
					}
					setDataRejection(RejectElements)
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};
	const FactorDcp = async (montos: any) => {
		setLoading(true);
		try {
			setAmount(montos)
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				credito: credit,
				ingresos: montos.ingresos,
				egresos: montos.egresos,
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			restructuringRequestAPI
				.FactorDcp(dataSendEncrypt)
				.then((res: any) => {
					if (res?.result?.code === '0000') {
						let obj = convenios.filter((e: any) => e.producto === typeSelected) || [];
						obj[0] = {
							...obj[0],
							difReaVsm: res?.result?.data.difReaVsm,
							difReaPesos: res?.result?.data.difReaPesos,
							difRoaVsm: res?.result?.data.difRoaVsm,
							difRoaPesos: res?.result?.data.difRoaPesos,
							factorReaPesos: res?.result?.data.creditoPesos,
							factorReaVsm: res?.result?.data.creditoVsm,
							factorRoaPesos: res?.result?.data.creditoPesos,
							factorRoaVsm: res?.result?.data.creditoVsm
						};
						let indice = convenios.findIndex((item: any) => {
							if (item.producto === obj[0].producto) {
								return true;
							}
						});
						let newConvenios = convenios;
						newConvenios[indice] = obj[0];
						setConvenios(newConvenios)
						setReestructura({
							...reestructura,
							convenios: newConvenios
						})
						setFactorPagos({
							factorPago: res?.result?.data?.factorPago,
							factorPagoDiario: res?.result?.data?.factorPagoDiario,
							factorPagoMensual: res?.result?.data?.factorPagoMensual,
							show: true,
							creditoPesos: res?.result?.data?.creditoPesos,
						})
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	};
	const encolarConvenio = async (caso: any, type: any) => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const paramsData = {
				nombreArchivo: `${caso}_Convenio.pdf`,
				credito: credit || '',
				caso: caso,
				tipoOperacion: type
			};
			const dataSendEncrypt = await encrypt(
				JSON.stringify(paramsData),
				key
			);
			restructuringRequestAPI
				.encolarConvenio(dataSendEncrypt)
				.then((res) => {
					if (res.result.code === '0000') {
						setTimeout(() => {
							FindCases()
						}, 3000);
						setDataToWait({
							wait: true,
							dateToShow: moment().add(60, 'seconds'),
						})
						localStorage.setItem('expireConvenio', moment().add(60, 'seconds').format());
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error.description || INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoading(false);
		}
	}
	const onNextPage = () => {
		if (typeSelected && typeSelected !== 'DCP') {
			setData({
				...data,
				ButtonEnable: true
			});
		}
		setView(view + 1);
	};
	const onPrevPage = () => {
		setData({
			...data,
			ButtonEnable: false
		});
		setView(view - 1);
	};
	const onChangePage = (page: number) => {
		setView(page);
	};

	const initNewCase = () => {
		AvailabilityAgreements();
		setView(0);
	}

	const resumableFile = new Resumable({
		target: `${process.env.REACT_APP_API_URL_REESTRUCTURAS}/VSMReestructuras-web/upload`,
		query: { usuario: paramsResumable.usuario, password: paramsResumable.password, },
		chunkSize: 1 * 1024 * 1024,
		simultaneousUploads: 4,
		testChunks: false,
		testMethod: 'POST',
		uploadMethod: 'POST',
		method: 'octet',
	});
	resumableFile.on('fileAdded', function () {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		resumableFile.upload();
		//encriptados en base64 sha-256
	});
	resumableFile.on('progress', function () {
		setLoading(true);
	});
	resumableFile.on('fileSuccess', function (file: any, message: string) {
		setLoading(false);
	});
	resumableFile.on('fileError', function (file, message) {
		setLoading(false);
		setAlert({
			show: true,
			message: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
			severity: 'error',
		});
	});

	const style = useStyles();
	return (
		<>
			<ModalLoading loading={loading} />
			<Layout>
				<div>
					<HeaderList
						key="HeaderSolicitudReestructuras"
						title="Solicitud de reestructuras"
					/>
					<Paper>
						<Box className={style.container}>
							{view === 0 && (
								<Home
									alertGral={alert}
									isSuceptible={suceptible}
									onNextPage={onNextPage}
								/>
							)}
							{view === 1 && (
								<SelectTypes
									convenios={convenios}
									setTypeSelected={setTypeSelected}
									onNextPage={onNextPage}
								/>
							)}
							{view === 2 && (
								<Product
									reestructuras={reestructura}
									convenios={convenios}
									onNextPage={onNextPage}
									onPrevPage={onPrevPage}
									typeSelected={typeSelected}
									FactorDcp={FactorDcp}
									factorPagos={factorPagos}
									alertGral={alert}
								/>
							)}
							{view === 3 && (
								<Summary
									reestructuras={reestructura}
									convenios={convenios}
									onNextPage={onNextPage}
									onPrevPage={onPrevPage}
									onChangePage={onChangePage}
									typeSelected={typeSelected}
									CreateCaseRequest={CreateCase}
									TypesRejection={TypesRejection}
									factorPagos={factorPagos}
									alertGral={alert}
								/>
							)}
							{view === 4 && (
								<Status
									dataCaso={dataCase}
									fileDowloaded={fileDowloaded}
									alertGral={alert}
									dataToWait={dataToWait}
								/>
							)}
							{view === 5 && (
								<FormRejection
									dataRejection={dataRejection}
									RechazoCaseRequest={RechazoCaseRequest}
									alertGral={alert}
									showModalRechazo={showModalRechazo}
									setShowModalRechazo={setShowModalRechazo}
								/>
							)}
						</Box>
					</Paper>
				</div>
			</Layout>
		</>
	);
};

export default RestructuringRequest;
