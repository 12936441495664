/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
//Assets

import imgAltanto from '../../../assets/img/mantente-al-tanto-2.png';
import imgProrroga from '../../../assets/img/prorroga.png';
import imgReestruct from '../../../assets/img/reestructura.png';
import imgAclaracion from '../../../assets/img/aclaracion.png';
// import imgHouseMoney from '../../../assets/img/housemoney.jpg';
import imgPagaCredit from '../../../assets/img/paga-credito-banner.jpg';

// import { CheckCircleOutlined } from '@mui/icons-material';
// import ViewMoreButton from '../components/viewMoreButton';

const AccreditedPaymentProblem = () => {
	const classes: any = useStyles();
	const navigate = useNavigate();

	// const itemsList = [
	// 	{ id: 1, text: 'En que puedes usar tu crédito Infonavit.' },
	// 	{
	// 		id: 2,
	// 		text: 'El monto y las condiciones financieras del crédito que se te puede otorgar.',
	// 	},
	// 	{ id: 3, text: 'Requisitos y documentos para solicitarlo.' },
	// ];

	return (
		<Grid container spacing={2}>
			<Grid item md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>
							¿Tienes problemas para realizar los pagos de tus mensualidades?
						</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Ingresa a Saldos y Movimientos para conocer el estatus de
							tu crédito, verificar si estás en prórroga o si eres
							candidato para alguna reestructura.
						</p>
						<p>
							Y si tienes duda en el registro de algún pago de tu crédito,
							levanta una Aclaración de pagos para que analicen tu situación.
						</p>
					</div>
				</Paper>
			</Grid>
			<Grid item md={12} lg={8}>
				<Grid container spacing={2}>
					<Grid item lg={12}>
						<div
							onClick={() =>
								navigate('/mi-credito/saldos-movimientos')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAltanto}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>

				<Grid container spacing={2} mt={1}>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/saldos-movimientos')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgProrroga}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/solicitud-reestructuras')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgReestruct}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
				</Grid>

				<Grid container spacing={2} mt={1}>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/aclaracion-pagos')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAclaracion}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<a href="/mi-ahorro/resumen-movimientos-ahorro">
							<img
								src={imgPagaCredit}
								className={classes.imageStyle}
								alt="img banner"
							/>
						</a>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default AccreditedPaymentProblem;
