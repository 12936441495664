import React, { Fragment } from 'react';
import { Close } from '@mui/icons-material';
import {
	Alert,
	FormControl,
	FormControlLabel,
	ListItemText,
	MenuItem,
	Select,
} from '@mui/material';
import { useStyles } from './styles';

export interface ISelectInput {
	id: string;
	name: string;
	label?: string;
	value: string | number | '';
	helperText?: string;
	type?: string;
	size?: 'small' | 'medium' | undefined;
	isRequired?: boolean;
	labelStyles?: React.CSSProperties;
	onChange?: (e: any) => void;
	disabled?: boolean;
	options?: any;
	placeholder?: string;
	containerStyles?: object;
	hiddenTextSelecciona?: boolean;
}

const SelectInput = ({
	id,
	name,
	label,
	value,
	options = [],
	helperText,
	isRequired,
	onChange,
	disabled,
	placeholder,
	hiddenTextSelecciona,
	containerStyles = {},
}: ISelectInput) => {
	const classes = useStyles();

	return (
		<Fragment>
			<FormControlLabel
				className={classes.container}
				style={containerStyles}
				label={
					<p className={classes.label}>
						{label}
						{isRequired && (
							<span className="label-required">*</span>
						)}
					</p>
				}
				labelPlacement={'top'}
				control={
					<FormControl
						fullWidth
						size="small"
						margin="dense"
						variant="outlined"
					>
						<Select
							id={id}
							fullWidth
							displayEmpty
							size="small"
							variant="outlined"
							name={name}
							value={value}
							onChange={onChange}
							disabled={disabled}
						>
							{!hiddenTextSelecciona && (
								<MenuItem value={''}>
									<ListItemText
										style={{
											display: 'flex',
											alignItems: 'end',
											margin: 0,
										}}
										primary="Selecciona"
									/>
								</MenuItem>
							)}
							{options.map(
								(option: any) =>
									option && (
										<MenuItem
											key={
												option.value?.id || option.value
											}
											value={option.value}
											disabled={option.disabled}
										>
											<ListItemText
												style={{
													display: 'flex',
													alignItems: 'end',
													margin: 0,
												}}
												primary={option.text}
												secondary={option.text2}
											/>
										</MenuItem>
									)
							)}
						</Select>
					</FormControl>
				}
			/>
			{helperText && (
				<Alert
					style={{
						marginTop: 5,
						marginBottom: 5,
						boxSizing: 'border-box',
					}}
					severity="error"
					sx={{ width: '100%' }}
					icon={<Close fontSize="inherit" />}
				>
					{helperText}
				</Alert>
			)}
		</Fragment>
	);
};

export default SelectInput;
