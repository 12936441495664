import { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { useStyles } from './styles/iwant-credit.styles';
import MiniMediaCard from './mini-media-card.component';
import useTallerMas from '../hooks/useElements';
import { getToolTip, WORKSHOP_VIEWS, getResponse } from '../utils';
import CardViewContent from './cardViewContent';
import { Elemento } from '../../../../../interfaces/tallerSaberMas';
import CarouselCustom from './CarouselCustom';
import Carousel from 'react-multi-carousel';
import { clsx } from 'clsx';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function IWantACredit() {
	const styles = useStyles();
	const { elementos, matches } = useTallerMas();
	const I_WANT_A_CREDIT = elementos[WORKSHOP_VIEWS.I_WANT_A_CREDIT.key];
	const [elemento, setElemento] = useState<Elemento>({} as Elemento);
	let aux = 0;

	useEffect(() => {
		if (
			I_WANT_A_CREDIT &&
			I_WANT_A_CREDIT.secciones[0].elementos.length > 0
		) {
			setElemento(I_WANT_A_CREDIT.secciones[0].elementos[0]);
		}
	}, []);

	const elementosJSX =
		I_WANT_A_CREDIT.secciones.map((sec, k) => (
			<div
				key={k}
				className={clsx([
					styles.miniMediaContainer,
					k % 2 !== 0 && styles.miniMediaContainerStriped,
				])}
			>
				<div className={styles.miniMediaHeader}>
					<span></span> {sec.nombre}
				</div>
				{sec.elementos.map((el, j) => (
					<MiniMediaCard
						variant="green"
						variantLabel={
							el.estatus === 0
								? 'pending'
								: el.estatus === 1
								? 'completed'
								: 'updated'
						}
						label={el.nombre}
						onClick={() => setElemento(el)}
						isPlaying={el.idElemento === elemento.idElemento}
						tooltip={getToolTip(matches)[el.idElemento]}
						isComplete={el.estatus === 1}
						key={j}
					/>
				))}
			</div>
		)) || [];

	const elementos2JSX = I_WANT_A_CREDIT.secciones.reduce<JSX.Element[]>(
		(acc, current) => {
			const elements = current.elementos.map((el, j) => (
				<div
					className={clsx([
						styles.miniMediaContainer2,
						aux > 0 && styles.miniMediaContainerStriped,
					])}
				>
					<div className={styles.miniMediaHeader}>
						{j === 0 && (
							<>
								<span></span> {current.nombre}
							</>
						)}
					</div>
					<MiniMediaCard
						variant="green"
						variantLabel={
							el.estatus === 0
								? 'pending'
								: el.estatus === 1
								? 'completed'
								: 'updated'
						}
						label={el.nombre}
						onClick={() => setElemento(el)}
						isPlaying={el.idElemento === elemento.idElemento}
						tooltip={getToolTip(matches)[el.idElemento]}
						isComplete={el.estatus === 1}
						key={j}
					/>
				</div>
			));
			aux = aux > 0 ? 0 : 1;
			return acc.concat(elements);
		},
		[]
	);

	return (
		<Paper elevation={2} className={styles.container}>
			<Grid container columns={16} spacing={2}>
				<Grid item sm={16}>
					<div className={styles.containerHeader}>
						<label className={styles.labelHeaderTitle}>
							Te prestamos, los créditos que necesites, para que
							compres casa nueva o existente, compres terreno,
							construyas, hagas mejora a tu casa o pagues una
							hipoteca previa, conoce lo que tenemos para ti, en
							cada uno de los siguientes materiales:
						</label>
					</div>
				</Grid>
				<Grid item sm={16} md={10} lg={10}>
					{/*	Contenido multimedia */}
					<CardViewContent
						elemento={elemento}
						setElemento={setElemento}
					/>
					<p className={styles.labelLegend}>
						<div
							dangerouslySetInnerHTML={{
								__html: elemento?.descripcion || '',
							}}
						></div>
					</p>
				</Grid>
				<Grid item sm={16} md={6} lg={6}>
					{!matches && <CarouselCustom items={elementosJSX} />}
				</Grid>
			</Grid>
			{matches && (
				<Carousel
					customLeftArrow={
						<div className={styles.arrowVideoL}>
							<div>
								<ChevronLeftIcon />
							</div>
						</div>
					}
					customRightArrow={
						<div className={styles.arrowVideoR}>
							<div>
								<ChevronRightIcon />
							</div>
						</div>
					}
					responsive={getResponse(elementos2JSX.length)}
				>
					{elementos2JSX.map((el) => el)}
				</Carousel>
			)}
		</Paper>
	);
}
