/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import jwt from 'jwt-decode';
import { Container, CssBaseline, useMediaQuery } from '@mui/material';
import CreditFooter from '../CreditRequestLayout/Footer';
import ExpandButton from '../../components/FloatExpandButton';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import chatBot from '../../assets/img/chatbot-icono.jpg';
import { useStyles } from './styles';
import { RootState } from '../../store';
import theme from '../../config/theme';
import ModalConfirmation from '../../components/ModalConfirmation';
import { actions } from '../../store/modules/session';
import { actions as creditActions } from '../../store/modules/credit';
import { actions as MiucActions } from '../../store/modules/miuc';
import { actions as saldAction } from '../../store/modules/saldo';
import { contactRequestAPI } from '../../api/modules/profile';
import { clsAssistantVirtual } from '../AssistantVirtual';
import {
	setInitPoll,
	setOpenAutomatic,
	ModalAuxiliar,
} from '../CalificaVirtual';
import { keyAPI } from '../../api/modules/key';
import likeIcon from '../../assets/img/icono-experiencia.png';

import useIdle from '../../hooks/useIdleTimer';

type Props = {
	children?: JSX.Element;
	childrenSubheader?: React.ReactNode;
	onlyTitle?: boolean;
	title?: string;
	creditRequestFooter?: boolean;
	actualizarElementosUbicacion?: any | undefined;
	width?: string | number;
};

const clsChat = new clsAssistantVirtual();

const Layout = ({
	children,
	childrenSubheader,
	onlyTitle,
	title,
	creditRequestFooter = false,
	actualizarElementosUbicacion,
	width,
}: Props) => {
	const classes = useStyles();
	const [btnCalifica, setBtnCalifica] = useState(false);
	const { isLogged, user } = useSelector((state: RootState) => state.session);
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const timeToExpire = 1000 * (user?.token_duration || 300);

	const active = async () => {
		if (isLogged) {
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				contactRequestAPI.tokenRefresh
					.post<any, any>('')
					.then((res) => {
						if (res && res.token) {
							const user = jwt(res.token) as any;
							dispatch(
								actions.setUser({
									...user,
									k: key,
									gender: user.curp[10] || 'H',
									token: res.token,
									remember: true,
								})
							);
						} else {
							signOff();
						}
					});
			} catch (error) {
				signOff();
			}
		}
	};
	const signOff = () => {
		if (isLogged) {
			dispatch(actions.signOut());
			dispatch(creditActions.clearCredit());
			dispatch(MiucActions.clearData());
			dispatch(saldAction.cleanSaldo());
			setTimeout(() => {
				user?.isImpersonalized
					? (window.location.href =
							'https://micuenta.infonavit.org.mx/')
					: navigate('/');
			}, 100);
		}
	};

	const {
		getRemainingTime,
		isPrompted,
		activate,
		remaining,
		setRemaining,
		openModalSession,
		setOpenModalSession,
	} = useIdle({ active, signOff, timeToExpire, isLogged });

	useEffect(() => {
		const interval = setInterval(() => {
			if (isPrompted()) {
				setRemaining(Math.ceil(getRemainingTime() / 1000));
			}
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, [getRemainingTime, isPrompted]);

	useEffect(() => {
		//setInitPoll();                                   // Se comenta temporal
		//setOpenAutomatic(isLogged, location.pathname);   // Se comenta temporal
	}, []);

	return (
		<React.Fragment>
			<ModalAuxiliar show={btnCalifica} setShow={setBtnCalifica} />
			<CssBaseline />
			{isLogged && (
				<React.Fragment>
					<ExpandButton
						id="ChatBotero"
						link="#Chat"
						title="Asistente Virtual Infonavit"
						icon={chatBot}
						iconStyle={{ height: 55 }}
						style={{
							position: 'absolute',
							right: 0,
							top: matches ? '47%' : '60%',
							zIndex: 99999,
						}}
						Click={() => {
							clsChat.showChat();
						}}
					/>
					<ExpandButton
						link="#Experiencia"
						title="Califica tu experiencia"
						icon={likeIcon}
						iconStyle={{ height: 55 }}
						style={{
							position: 'absolute',
							right: 0,
							top: matches ? '57%' : '70%',
						}}
						Click={() => {
							setBtnCalifica(true);
						}}
					/>
				</React.Fragment>
			)}
			<div>
				<Navbar
					onlyTitle={onlyTitle}
					title={title || ''}
					subheaderChild={childrenSubheader}
				/>
				{isLogged && (
					<ModalConfirmation
						open={openModalSession}
						confirmLabel="Continuar sesión"
						cancelLabel="Terminar sesión"
						onConfirm={() => {
							setOpenModalSession(false);
							active();
							activate();
						}}
						onClose={() => {
							signOff();
						}}
						cancelButton
						showTitle
						title="Cierre de sesión"
						width="sm"
					>
						<p>
							Tu sesión finalizará por inactividad en
							{remaining === 60 || remaining === 60000
								? ' 1:00'
								: `${
										remaining.toString().length === 2
											? ` 00:${remaining}`
											: ` 00:0${remaining}`
								  }`}
							. ¿Deseas continuar navegando?
						</p>
					</ModalConfirmation>
				)}
				<Container
					maxWidth={width ? false : 'lg'}
					className={classes.root}
					style={{
						width,
						minHeight:
							location.pathname ===
							'/mi-tramite-credito/precalificacion-puntos'
								? '68vh'
								: '80vh',
					}}
				>
					{children}
				</Container>
				{creditRequestFooter && (
					<CreditFooter
						actualizarElementosUbicacion={
							actualizarElementosUbicacion
						}
					/>
				)}
				<Footer />
			</div>
		</React.Fragment>
	);
};

export default Layout;
