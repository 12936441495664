import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: 20,
		},
		Input_Amount: {
			marginLeft: '10px',
			marginRight: '10px',
			width: '100%',
			[theme.breakpoints.down('md')]: {
				marginLeft: '0px',
				marginRight: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: '0px',
				marginRight: '0px',
			},
		},
		Button_Amount: {
			width: '260px',
			margin: '30px auto',
		},
		Box_Agreement_Table: {

			paddingLeft: '60px',
			paddingRight: '60px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '0px',
				paddingRight: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '0px',
				paddingRight: '0px',
			},
		},
		Box_Amount: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				justifyContent: 'space-around',
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				justifyContent: 'space-around',
			},
		},

		Box_Buttons: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			width: '540px',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				flexDirection: 'column-reverse',
				justifyContent: 'space-around',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				flexDirection: 'column-reverse',
				justifyContent: 'space-around',
			},
		},
		Box_Buttons_Full: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			width: '100%',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				flexDirection: 'column-reverse',
				justifyContent: 'space-around',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				flexDirection: 'column-reverse',
				justifyContent: 'space-around',
			},
		},
		bold_Center: {
			fontSize: '22px',
			fontWeight: 'bold',
			textAlign: 'center',
		},
		bold_Center_Blue: {
			color: '#293990',
			display: 'flex',
			textAlign: 'center',
			fontWeight: 'bold',
			fontSize: 28,
			lineHeight: 1,
			'&>h2': {
				color: theme.palette.info.dark,
			},
			'&>p': {
				[theme.breakpoints.down('sm')]: {
					display: 'none',
				},
			},
		},
		bold_Left: {
			fontSize: '18px',
			fontWeight: 'bold',
			textAlign: 'left',
		},
		containerText: {
			padding: '5px 40px'
		},
		textAlign_Center: {
			textAlign: 'center',
			paddingLeft: '45px',
			paddingRight: '45px',
			lineHeight: '1',
			fontSize: '16px',
			fontFamily: 'Geomanist',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '0px',
				paddingRight: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '0px',
				paddingRight: '0px',
			},
		},
		textAlign_Center_spaciado: {
			textAlign: 'center',
			paddingLeft: '45px',
			paddingRight: '45px',
			lineHeight: '1.5',
			fontSize: '16px',
			fontFamily: 'Geomanist',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '0px',
				paddingRight: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '0px',
				paddingRight: '0px',
			},
		},
		textAlign_Left: {
			textAlign: 'left',
			paddingLeft: '45px',
			paddingRight: '45px',
			lineHeight: '1',
			fontSize: '16px',
			fontFamily: 'Geomanist',
			[theme.breakpoints.down('md')]: {
				textAlign: 'center',
				paddingLeft: '0px',
				paddingRight: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center',
				paddingLeft: '0px',
				paddingRight: '0px',
			},
		},
		text: {
			lineHeight: '1',
			fontSize: '16px',
			fontFamily: 'Geomanist',
		},
		textAlign_Bold: {
			lineHeight: '1',
			fontSize: '16px',
			fontFamily: 'Geomanist',
			fontWeight: 'bold',
		},
		textAlign_Bold_Title: {
			lineHeight: '1',
			fontSize: '22px',
			fontFamily: 'Geomanist',
			fontWeight: 'bold',
		},
		textAlign_Bold_Blue: {
			lineHeight: '1',
			fontSize: '16px',
			fontFamily: 'Geomanist',
			fontWeight: 'bold',
			color: '#293990',
		},
		BoxButton: {
			width: '260px',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				marginBottom: '20px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				marginBottom: '20px',
			},
		},
		BoxAlert: {
			paddingLeft: '45px',
			paddingRight: '45px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '0px',
				paddingRight: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '0px',
				paddingRight: '0px',
			},
		},
		divider: {
			width: '100%',
			height: 1.5,
			marginTop: 30,
			marginBottom: 30,
			backgroundColor: '#E1E3E6',
		},
		BoxRadioButton: {
			paddingLeft: '40px',
			[theme.breakpoints.down('md')]: {
				paddingLeft: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: '0px',
			},
		},
		table1: {
			display: 'block',
		},
		GrayBoxContainer: {
			display: 'flex',
			justifyContent: 'end',
			marginTop: '40px',
			marginBottom: '20px',
		},
		GrayBoxCondicionesActuales: {
			display: 'flex',
			justifyContent: 'center',
			flexWrap: 'nowrap',
			background: '#f2f2f2',
			flexDirection: 'row',
			width: '380px',
			marginLeft: '20px',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				marginLeft: '0px',
				marginBottom: '15px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				marginLeft: '0px',
				marginBottom: '15px',
			},
		},
		GrayBoxCondicionesReestructuras: {
			display: 'flex',
			justifyContent: 'center',
			flexWrap: 'nowrap',
			background: '#f2f2f2',
			flexDirection: 'row',
			width: '450px',
			marginLeft: '20px',
			maxWidth: '290px',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				marginLeft: '0px',
				marginBottom: '15px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				marginLeft: '0px',
				marginBottom: '15px',
			},
		},
		textGrayBox: {
			textAlign: 'center',
			fontSize: '16px',
			fontFamily: 'Geomanist',
			color: '#293990',
			fontWeight: 'bold',
			marginTop: '12px',
			marginBottom: '12px',
		},
		textAlign_Red: {
			lineHeight: '1',
			fontSize: '18px',
			fontFamily: 'Geomanist, Regular',
			color: '#D1001F',
			letterSpacing: '0px',
			opacity: 1,
			'&:hover': {
				cursor: 'pointer',
			},
		},
		textCheck: {
			lineHeight: '1',
			fontSize: '14px',
			fontFamily: 'Geomanist, Regular',
			color: '#333333',
			letterSpacing: '0px',
			opacity: 1,
		},
		iconoDescarga: {
			width: '24px',
			height: '24px',
		},
		Box_Button_Finalizar: {
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'row',
			//paddingTop: '10px',
			paddingBottom: '30px',
			width: '250px',
			[theme.breakpoints.down('md')]: {
				width: '100%',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		Box_Motivo_Rechazo: {
			marginLeft: '240px',
			marginRight: '240px',
			[theme.breakpoints.down('md')]: {
				marginLeft: '0px',
				marginRight: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: '0px',
				marginRight: '0px',
			},
		},
		Cartas_Title_Bold: {
			fontSize: '20px',
			fontWeight: 600,
		},
		Cartas_Parrafo: {
			fontSize: '16px',
			textAlign: 'justify',
			lineHeight: '22px',
			fontFamily: 'Geomanist, Regular',
		},
		Cartas_Title_Bold_Fecha: {
			fontSize: '16px',
			fontWeight: 'bold',
		},
		Cartas_Title_Blue: {
			fontSize: '20px',
			fontWeight: 600,
			color: '#283990',
		},
		Cartas_Title: {
			fontSize: '20px',
			fontWeight: 400,
		},
		Cartas_Bold: {
			fontSize: '16px',
			fontWeight: 'bold',
			textAlign: 'left',
		},
		Cartas_Parrafo_Left: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '14px',
			textAlign: 'justify',
			lineHeight: '19px',
		},
		textoModal: {
			fontFamily: 'Geomanist, Medium',
			fontWeight: '500',
			fontSize: '30px',
			color: '#293990',
			letterSpacing: '0px',
			textAlign: 'center',
		},

		textoAjustesFinancieros: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '14px',
			color: '#333333',
			letterSpacing: '0px',
			textAlign: 'left',
			lineHeight: '18px',
		},

		tabla2Encabezados: { width: '14.28%', fontWeight: 'bold', fontSize: '15px', textAlign: 'right', paddingRight: '50px', },
		tabla2Datos: { width: '14.28%', fontSize: '15px', textAlign: 'right', paddingRight: '50px' },
		tabla2DatosBold: { width: '14.28%', fontSize: '15px', fontWeight: 'bold' },
		tabla2TitulosDiv: { width: '28.56%', paddingRight: '15px' },
		tabla2Titulos: {
			display: 'flex',
			alignContent: 'center',
			justifyContent: 'center',
			background: '#f2f2f2',
			height: '65px',
			alignItems: 'center',
			[theme.breakpoints.down('md')]: {
				height: '100%',
				'&>p': {
					fontSize: '13px',
				}
			},
		},
		contentFooterDataLink: {
			color: '#D1001F',
			display: 'flex',
			fontSize: '15px',
			alignItems: 'center',
			justifyContent: 'center',
		},
		txtPrincipales: {
			color: '#333333',
			fontSize: '15px !important',
			verticalAlign: 'top',
			letterSpacing: 0,
			opacity: 1,
			[theme.breakpoints.down('sm')]: {
				fontSize: '13px !important',
			},
		},
		contentFile: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		root: {
			'& .MuiLinearProgress-barColorPrimary': {
				backgroundColor: 'blue',
			},
		},
	})
);
