/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useStyles } from './style';
import CustomButton from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import iconDownload from '../../assets/img/icono-descarga.png';
import { formatDate } from '../../interfaces/SaldAndMovement';
import CustomAlert from '../../components/CustomAlert';
import LinearProgress from '@mui/material/LinearProgress';

interface Props {
	dataCaso: any;
	fileDowloaded: any;
	alertGral: any;
	dataToWait: any;
}

const Status = ({ dataCaso, fileDowloaded, alertGral, dataToWait }: Props) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	const getPdf = async () => {
		let b64Data = fileDowloaded.payload
		const contentType = 'application/pdf';
		const fileName = 'Convenio.pdf';
		const blob = b64toBlob(b64Data, contentType);
		downloadFile(blob, fileName);
	};

	const b64toBlob = (b64Data: any, contentType: any) => {
		contentType = contentType || '';
		const sliceSize = 512;
		const byteCharacters = window.atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	const downloadFile = (blob: any, fileName: any) => {
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const style = useStyles();
	return (
		<>
			<div style={{ padding: '5px 30px' }}>
				<h2 style={{ color: '#293990', textAlign: 'center', paddingBottom: 20 }}>
					Gracias por utilizar los servicios digitales del Infonavit
				</h2>
				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Número de caso
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						<label>{dataCaso.noCaso}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />
				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Fecha de solicitud
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						<label>{formatDate(dataCaso?.fecha, '/')}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Servicio
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						<label>Solicitud de reestructura</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Estatus
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						{!dataToWait.wait ? dataCaso.estatusDetalle : 'Estamos cargando tu documentación'}
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Fecha de estatus
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						<label>{formatDate(dataCaso?.fechaEstatus, '/')}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />

				<Grid
					container
					columns={12}
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<h3
							style={{
								fontSize: 18,
								margin: 0,
								textAlign: matches ? 'center' : 'initial',
							}}
						>
							Descripción
						</h3>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={9}
						lg={9}
						style={{ textAlign: matches ? 'center' : 'right' }}
					>
						<label>{dataCaso?.descripcion}</label>
					</Grid>
				</Grid>
				<hr style={{ marginBottom: 18, marginTop: 18, opacity: 0.1 }} />

				{dataToWait.wait && (
					<div>
						<div>
							<div className={style.contentFile}>
								<Box sx={{ width: '50%', marginTop: 5 }}>
									<LinearProgress />
								</Box>
							</div>
						</div>
						<div style={{ margin: matches ? '5px 10px' : '20px 200px', textAlign: 'center' }}>
							<span style={{ color: '#7F7F7F', fontSize: 15 }}>
								Tiempo aproximado de espera 1 min.
							</span>
						</div>
					</div>
				)}

				{!dataToWait.wait && (
					<div>
						{fileDowloaded.valid && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: '1em',
								}}
							>
								<div className={style.contentFooterDataLink}>
									<div onClick={getPdf}
										style={{
											cursor: 'pointer',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<label style={{ marginTop: 7, cursor: 'pointer' }}>
											Descargar tu convenio
										</label>
										<img src={iconDownload} alt="download" />
									</div>
								</div>
							</Box>
						)}

						<Box className={style.BoxAlert}>
							<CustomAlert
								message={alertGral?.message}
								severity={alertGral?.severity}
								show={alertGral?.show}
							/>
						</Box>

						<div
							style={{
								maxWidth: 800,
								margin: 'auto',
								marginTop: 30,
							}}
						>
							<div
								style={{
									width: matches ? '100%' : '600px',
									margin: '0px auto',
									display: matches ? 'block' : 'flex',
									justifyContent: 'space-between',
								}}
							>
								<CustomButton
									label="Finalizar"
									onClick={() => navigate('/')}
									variant="solid"
									styles={{
										width: 260,
										height: 40,
										margin: '0px auto',
									}}
								/>
							</div>
						</div>
					</div>
				)}

			</div>
		</>
	);
};

export default Status;
