import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icono: {
			color: 'white',
			fontSize: 8,
		},
		divContainer: {
			height: '20px',
			width: '20px',
			display: 'contents',
			cursor: 'pointer',
		},
		divCircle: {
			border: '1px solid red',
			borderRadius: '50%',
			background: 'red',
			display: 'flex',
			height: '100%',
			width: '100%',
			justifyContent: 'center',
			alignContent: 'center',
			textAlign: 'center',
		},
		colorTitle: {
			color: theme.palette.info.main,
		},
		parrafo: {
			fontFamily: 'Geomanist, Regular',
			fontSize: '14px',
			lineHeight: '1.5',
		},
		showInfoClass: {
			position: 'absolute',
			width: 120,
			fontSize: 14,
			fontWeight: 400,
		},
		customClass: {
			width: 120,
			fontSize: 14,
			fontWeight: 400,
		},
		HelpClass: {
			color: '#D1001F',
			fontSize: 18,
			marginLeft: 25,
		},
		titleSuperior: {
			color: theme.palette.info.main,
			margin: 0,
		},
		buttonCustom: {
			width: 250,
			margin: 'auto',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				margin: '10px 0px',
			},
		},
		BoxCustom2: {
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'row',
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column-reverse',
			},
		},
	})
);

export const stylesInLine: { [index: string]: React.CSSProperties } = {
	linkClassOne: {
		fontWeight: 700,
	},
	HelpClass: {
		color: '#D1001F',
		fontSize: 18,
	},
};
