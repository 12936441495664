import { Box, Grid } from '@mui/material';
import Layout from '../../../../components/Layout';
import { MIUC_PROCESS_VIEWS } from '../../constants/process';
import { MIUC_PRODUCTS } from '../../constants/products';
import { SecureCredit } from '../SecureCredit/secureCredit';
import { NoPoints } from '../../components/NoPoints/noPoints';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useController } from '../../hooks/useController';
import { useGeneralStyles } from '../styles/general.styles';
import { RootState } from '../../../../store';
import ViewPoints from '../../components/ViewPoints';
import { useSinPuntos } from './useSinPuntos';
import PuntosInsuficientesMiuc from '../puntosInsuficientes';
import { AmpliarReparar } from '../AmpliarReparar/ampliarReparar';

export const SinPuntos = () => {
	const { view, onSelect } = useSinPuntos();
	const generalStyle = useGeneralStyles();

	const size = useMemo(() => {
		if (view !== MIUC_PROCESS_VIEWS.NO_POINTS) {
			return 12;
		} else {
			return 8;
		}
	}, [view]);

	return (
		<Layout creditRequestFooter>
			<Box className={generalStyle.container}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={size}>
							{view === MIUC_PROCESS_VIEWS.NO_POINTS && (
								<NoPoints onSelect={onSelect} />
							)}
							{view === MIUC_PROCESS_VIEWS.SECURE_CREDIT && (
								<SecureCredit />
							)}
							{view === MIUC_PROCESS_VIEWS.INFONAVIT_SUPPORT && (
								<PuntosInsuficientesMiuc />
							)}
              {view === MIUC_PROCESS_VIEWS.MEJORAVIT && (
								<AmpliarReparar />
							)}
					</Grid>
					{
						view === MIUC_PROCESS_VIEWS.NO_POINTS && (
							<Grid item xs={12} md={4}>
								<Box sx={{ textAlign: 'center' }}>
									<ViewPoints />
								</Box>
							</Grid>
						)
					}
				</Grid>
			</Box>
		</Layout>
	);
};
