// <---------- Dependencias ----------------->
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import ReactGAV3 from 'react-ga';
import ReactGA from 'react-ga4';

// <---------- Componentes Principales ----------------->
import ProtectedRoute from './protected';
import { actions } from '../store/modules/session';
import { actions as creditActions } from '../store/modules/credit';
import { RootState } from '../store';

// <---------- Componentes Secundarios ----------------->
import ModalLoading from '../components/ModalLoading';

// <---------  Paginas ---------------------->
import ChangePassPage from '../pages/ChangePassword';
import LoginPage from '../pages/Login';
import RegisterPage from '../pages/Register';
import ServiceOfficePage from '../pages/ServiceOffice';
import ContactPage from '../pages/Profile/Contact';
import ExtraordinaryCont from '../pages/ExtraordinaryContributions';
import ResetPasswordPage from '../pages/ResetPassword';
import PageConfirmation from '../pages/PageConfirmation';
import AssociateNssCredit from '../pages/AssociateNssCredit';
import InfonavitPartnerView from '../pages/InfonavitPartner';
import MySavingPage from '../pages/MySaving';
import ReturnOverpayments from '../pages/ReturnOverpayments';
import SummaryMovementsDownload from '../pages/SummaryMovements/DownloadPdf';
import ContractConfirmation from '../pages/Profile/Contact/ContactInformation/ContractConfirmation';
import SavingCalculator from '../pages/SavingCalculator';
import RFCCorrection from '../pages/RFCCorrection';
import NotFound from '../pages/NotFound404';
import Maintenance from '../pages/Maintenance';
import ConfirmRecoveryPassword from '../pages/ResetPassword/ConfirmationEmail';
import SaldAndMovements from '../pages/SaldAndMovements';
import CURPCorrection from '../pages/CURPCorrection';
import DeedsDelivery from '../pages/DeedsDelivery';
import HomeMeasure from '../pages/HomeMeasure';
import SharedResponsability from '../pages/SharedResponsability';
import SharedResponsabilityAdviser from '../pages/SharedResponsabilityAdviser';
import SelectionOfNotaries from '../pages/SelectionOfNotaries';
import ConstanciaDeclaracionInteresesAnual from '../pages/ConstanciaInteresesDeclaracionAnual';
import Notices from '../pages/Notice';
import ServiceChannels from '../pages/ServiceChannels';
import ConsultationWorkRelations from '../pages/ConsultationWorkRelations';
import EarlySettlement from '../pages/EarlySettlement';
import FollowAttachDocuments from '../pages/FollowAttachDocuments';
import CancellationView from '../pages/CancellationLetter';
import EcotechConstancy from '../pages/EcotechConstancy';
import MakeAppointment from '../pages/MakeAppointment';
import CancelledPayments from '../pages/CancelledPayments';
import MakeAppointment24 from '../pages/MakeAppointment24';
import RestructuringRequest from '../pages/RestructuringRequest';
import CalculatorUma from '../pages/CalculatorUma';
import Impersonalizacion from '../pages/Impersonalizacion';
import ElectroNotify from '../pages/ElectroNotify';
import UnpairDevice from '../pages/UnpairDevice';
import PaymentClarification from '../pages/PaymentClarification';
import CurpCorrectionRegister from '../pages/CurpCorrectionRegister';
import RfcCorrectionRegister from '../pages/RfcCorrectionRegister';
import ProtectedNotLoggedRoute from './protectedNotLogged';
import SummarySavings from '../pages/SummarySavings';
import ReturnSavings from '../pages/ReturnSavings';
import Prorogation from '../pages/Prorogation';
import FollowComplaint from '../pages/FollowComplaint';
import HowCreditGoing from '../pages/HowMyCreditGoing';
import Main from '../pages/Main';
import NipInfonatel from '../pages/NipInfonatel/index';
//import HomeMIUC from '../pages/MIUC/views/Home';
import HipotecaMiuc from '../pages/MIUC/views/hipoteca';
import { ComprarVivienda } from '../pages/MIUC/views/ComprarVivienda/comprarVivienda';
import ConstruirMIUC from '../pages/MIUC/views/construir';
import CrediterrenoMIUC from '../pages/MIUC/views/crediterreno';
import { SinRelacionLaboral } from '../pages/MIUC/views/SinRelacionLaboral/sinRelacionLaboral';
import CreditStatus from '../pages/MIUC/views/CreditStatus';
import MejoraSi from '../pages/MIUC/views/MejoraSi';
import { SinPuntos } from '../pages/MIUC/views/SinPuntos/sinPuntos';
import { MejoraHogar } from '../pages/MIUC/views/MejoraHogar/mejoraHogar';
import TallerSaberMas from '../pages/MIUC/views/TallerSaberMas';
import Agroindustry from '../pages/MIUC/views/Agroindustry';
import OnlineCredit from '../pages/MIUC/components/OnlineCredit/onlineCredit';
import { Precalificacion } from '../pages/MIUC/views/Precalificacion/precalificacion';
import { EvaluationFlow } from '../pages/MIUC/components/EvaluationUnit/evaluationFlow';
import DocumentViewMIUC from '../pages/MIUC/views/DocumentViewMIUC';
import SupportCertificate from '../pages/SupportCertificate';
import { MenuHowCreditGoing } from '../pages/MIUC/views/CreditStatus/views/MenuHowCreditGoing';
import VoluntaryContributions from '../pages/MIUC/components/VoluntaryContributions';

export const Router = () => {
	const { isLogged, remember } = useSelector(
		(state: RootState) => state.session
	);
	const { isLoading } = useSelector((state: RootState) => state.loading);
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		if (!remember) {
			dispatch(actions.signOut());
			dispatch(creditActions.clearCredit());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		ReactGA.send({ hitType: 'pageview', page: location.pathname });
		ReactGAV3.pageview(location.pathname);
	}, [location]);

	return (
		<>
			<Routes>
				<Route
					path="/"
					element={
						isLogged ? <Navigate to="inicio" /> : <LoginPage />
					}
				/>
				<Route
					path="/email-confirmation"
					element={<ContractConfirmation />}
				/>
				<Route
					path="registro"
					element={
						<ProtectedNotLoggedRoute component={RegisterPage} />
					}
				/>
				<Route
					path="correccion-curp"
					element={
						<ProtectedNotLoggedRoute
							component={CurpCorrectionRegister}
						/>
					}
				/>
				<Route
					path="correccion-rfc"
					element={
						<ProtectedNotLoggedRoute
							component={RfcCorrectionRegister}
						/>
					}
				/>
				<Route
					path="cambio-contrasenia"
					element={
						<ProtectedNotLoggedRoute
							component={ResetPasswordPage}
						/>
					}
				/>
				<Route path="PageConfirmation" element={<PageConfirmation />} />
				<Route
					path="oficinas-atencion"
					element={
						<ProtectedNotLoggedRoute
							component={ServiceOfficePage}
						/>
					}
				/>
				<Route
					path="canales-servicio"
					element={
						<ProtectedNotLoggedRoute component={ServiceChannels} />
					}
				/>
				<Route
					path="impersonalizacion"
					element={
						<ProtectedNotLoggedRoute
							component={Impersonalizacion}
						/>
					}
				/>
				<Route
					path="confirm-recover-password"
					element={<ConfirmRecoveryPassword />}
				/>
				{/** Not Found page */}
				<Route
					path="404"
					element={<ProtectedRoute component={NotFound} />}
				/>
				<Route
					path="*"
					element={<ProtectedRoute component={Maintenance} />}
				/>
				{/** Protected Routes */}
				<Route
					path="inicio"
					element={<ProtectedRoute component={Main} />}
				/>
				{/* Mi credito */}
				<Route
					path="mi-credito/entrega-escrituras"
					element={<ProtectedRoute component={DeedsDelivery} />}
				/>
				<Route
					path="mi-credito/constancia-ecotecnologias"
					element={<ProtectedRoute component={EcotechConstancy} />}
				/>
				<Route
					path="mi-credito/carta-cancelacion-hipoteca"
					element={<ProtectedRoute component={CancellationView} />}
				/>
				{/*COMENTAR Y DESCOMENTAR EN QA*/}
				<Route
					path="/mi-credito/responsabilidad-compartida-programa-conversion-pesos"
					element={
						<ProtectedRoute component={SharedResponsability} />
					}
				/>
				<Route
					path="mi-credito/responsabilidad-compartida/asesor"
					element={<SharedResponsabilityAdviser />}
				/>
				<Route
					path="mi-credito/saldos-movimientos"
					element={<ProtectedRoute component={SaldAndMovements} />}
				/>
				<Route
					path="mi-credito/programa-descuentos-liquidacion-anticipada"
					element={<ProtectedRoute component={EarlySettlement} />}
				/>
				<Route
					path="mi-credito/avisos-suspension-retencion-modificacion-descuentos"
					element={<ProtectedRoute component={Notices} />}
				/>
				<Route
					path="mi-credito/devolucion-pagos-exceso"
					element={<ProtectedRoute component={ReturnOverpayments} />}
				/>
				<Route
					path="mi-credito/constancia-intereses-declaracion-anual"
					element={
						<ProtectedRoute
							component={ConstanciaDeclaracionInteresesAnual}
						/>
					}
				/>
				<Route
					element={
						<ProtectedRoute component={RestructuringRequest} />
					}
					path="mi-credito/solicitud-reestructuras"
				/>
				<Route
					element={
						<ProtectedRoute component={PaymentClarification} />
					}
					path="mi-credito/aclaracion-pagos"
				/>
				<Route
					path="mi-credito/solicitud-prorroga"
					element={<ProtectedRoute component={Prorogation} />}
				/>
				{/* Mi Perfil */}
				<Route
					path="mi-perfil/cambiar-contrasenia"
					element={<ProtectedRoute component={ChangePassPage} />}
				/>
				<Route
					path="mi-perfil/actualizar-datos-contacto"
					element={<ProtectedRoute component={ContactPage} />}
				/>
				<Route
					path="mi-perfil/actualizar-datos-contacto/:validate"
					element={<ProtectedRoute component={ContactPage} />}
				/>
				<Route
					path="mi-tramite-credito/asocia-nss"
					element={<ProtectedRoute component={AssociateNssCredit} />}
				/>
				<Route
					path="mi-tramite-credito/sumar-creditos"
					element={<ProtectedRoute component={AssociateNssCredit} />}
				/>
				<Route
					path="mi-perfil/correccion-rfc"
					element={<ProtectedRoute component={RFCCorrection} />}
				/>
				<Route
					path="mi-perfil/socio-infonavit"
					element={
						<ProtectedRoute component={InfonavitPartnerView} />
					}
				/>
				<Route
					element={<ProtectedRoute component={UnpairDevice} />}
					path="mi-perfil/desvincula-tu-dispositivo-movil"
				/>
				<Route
					element={<ProtectedRoute component={CURPCorrection} />}
					path="mi-perfil/correccion-curp-nombre"
				/>
				<Route
					path="mi-perfil/nip-infonatel"
					element={<ProtectedRoute component={NipInfonatel} />}
				/>
				{/* Mi tramite credito */}
				<Route
					element={<ProtectedRoute component={MenuHowCreditGoing} />}
					path="mi-tramite-credito/en-que-va-mi-solicitud-credito"
				/>
				<Route
					path="/mi-tramite-credito/seleccion-notarios"
					element={<ProtectedRoute component={SelectionOfNotaries} />}
				/>
				<Route
					path="mi-tramite-credito/hogar-medida"
					element={<ProtectedRoute component={HomeMeasure} />}
				/>
				{/* <Route
					path="/mi-tramite-credito/precalificacion-puntos"
					element={<ProtectedRoute component={CreditRequest} />}
				/> */}
				<Route
					path="/mi-tramite-credito/precalificacion-puntos"
					element={<ProtectedRoute component={Precalificacion} />}
				/>
				<Route
					path="mi-tramite-credito/confirmacion-tramite"
					element={<ProtectedRoute component={ElectroNotify} />}
				/>
				<Route
					path="mi-tramite-credito/certificado-apoyo-infonavit"
					element={<ProtectedRoute component={SupportCertificate} />}
				/>
				<Route
					path="mi-tramite-credito/devolucion-pagos-creditos-cancelados"
					element={<ProtectedRoute component={CancelledPayments} />}
				/>
				{/* Mi Ahorro */}
				<Route
					path="mi-ahorro/devolucion-fondo-ahorro-72-92"
					element={<ProtectedRoute component={ReturnSavings} />}
				/>
				<Route
					path="mi-ahorro/consulta-relaciones-laborales"
					element={
						<ProtectedRoute component={ConsultationWorkRelations} />
					}
				/>
				<Route
					path="mi-ahorro/haz-aportaciones-extraordinarias"
					element={<ProtectedRoute component={ExtraordinaryCont} />}
				/>
				<Route
					path="mi-ahorro/cuanto-ahorro-tengo"
					element={<ProtectedRoute component={MySavingPage} />}
				/>
				<Route
					path="mi-ahorro/calculadora-ahorro"
					element={<ProtectedRoute component={SavingCalculator} />}
				/>
				<Route
					path="mi-ahorro/resumen-movimientos-ahorro"
					element={<ProtectedRoute component={SummarySavings} />}
				/>
				<Route
					path="mi-ahorro/resumen-movimientos-ahorro/download"
					element={
						<ProtectedRoute component={SummaryMovementsDownload} />
					}
				/>
				{/* Contactanos */}
				<Route
					path="contactanos/canales-servicio"
					element={<ProtectedRoute component={ServiceChannels} />}
				/>
				<Route
					path="contactanos/haz-una-cita"
					element={<ProtectedRoute component={MakeAppointment24} />}
				>
					<Route
						path=":caso/:modulo"
						element={
							<ProtectedRoute component={MakeAppointment24} />
						}
					/>
				</Route>
				<Route
					path="contactanos/oficinas-atencion"
					element={<ProtectedRoute component={ServiceOfficePage} />}
				/>
				<Route
					path="contactanos/presenta-sigue-queja"
					element={<ProtectedRoute component={FollowComplaint} />}
				/>
				<Route
					path="accesorios/calculadora-pesos-unidad-medida-actualizacion-uma"
					element={<ProtectedRoute component={CalculatorUma} />}
				/>
				<Route
					path="contactanos/sigue-caso-adjunta-documentos"
					element={
						<ProtectedRoute component={FollowAttachDocuments} />
					}
				/>
				{/* Precalificacion Puntos */}
				<Route
					path="precalificacion-puntos/comprar-vivienda"
					element={<ProtectedRoute component={ComprarVivienda} />}
				/>
				<Route
					path="precalificacion-puntos/pagar-hipoteca"
					element={<ProtectedRoute component={HipotecaMiuc} />}
				/>
				<Route
					path="precalificacion-puntos/construir-una-vivienda"
					element={<ProtectedRoute component={ConstruirMIUC} />}
				/>
				<Route
					path="precalificacion-puntos/mejora-tu-hogar"
					element={<ProtectedRoute component={MejoraHogar} />}
				/>
				<Route
					path="precalificacion-puntos/sin-relacion-laboral"
					element={<ProtectedRoute component={SinRelacionLaboral} />}
				/>
				<Route
					path="precalificacion-puntos/comprar-terreno"
					element={<ProtectedRoute component={CrediterrenoMIUC} />}
				/>
				<Route
					path="precalificacion-status"
					element={<ProtectedRoute component={CreditStatus} />}
				/>
				<Route
					path="precalificacion-puntos/sin-puntos"
					element={<ProtectedRoute component={SinPuntos} />}
				/>
				<Route
					path="precalificacion-puntos/mejora-si"
					element={<ProtectedRoute component={MejoraSi} />}
				/>
				<Route
					path="mi-tramite-credito/taller-saber-mas"
					element={<ProtectedRoute component={TallerSaberMas} />}
				/>
				<Route
					path="precalificacion-puntos/agroindustria"
					element={<ProtectedRoute component={Agroindustry} />}
				/>
				<Route
					path="precalificacion-puntos/credito-en-linea"
					element={<ProtectedRoute component={OnlineCredit} />}
				/>
				<Route
					path="precalificacion-puntos/unidades-valuacion"
					element={<ProtectedRoute component={EvaluationFlow} />}
				/>
				<Route
					path="precalificacion-puntos/comprar-terreno-o-comprar-terreno-y-construir-una-vivienda"
					element={<ProtectedRoute component={CrediterrenoMIUC} />}
				/>
				{/*<Route
					path='/aportaciones-voluntarias'
					element={<ProtectedRoute component={VoluntaryContributions} />}
				/> DESHABILITAR AV */}

				{/* Url de descarga para MIUC */}
				<Route
					path="guias-de-pasos-a-seguir/:documento"
					element={<ProtectedRoute component={DocumentViewMIUC} />}
				/>
			</Routes>
			<ModalLoading loading={isLoading} />
		</>
	);
};
