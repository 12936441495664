import { Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/Button';
import CustomAlert from '../../components/CustomAlert';
import ModalLoading from '../../components/ModalLoading';
import { useStyles } from './style';

interface Props {
	alertGral: any;
	isSuceptible: any;
	onNextPage: () => void;
}

const Home = ({ alertGral, isSuceptible, onNextPage }: Props) => {
	const [loading] = useState(false);
	const navigate = useNavigate();

	const style = useStyles();
	return (
		<>
			<ModalLoading loading={loading} />
			<p className={style.bold_Center}>
				Verifica si puedes solicitar una reestructura
			</p>
			<div className={style.containerText}>
				<p className={style.textAlign_Center}>
					Si eres candidato a un Apoyo de Reestructura, esto significa un acuerdo
					de voluntades celebrado entre el Infonavit y la persona acreditada otorgando un
					apoyo respecto a la deuda conforme a la capacidad de pago y que cuente con
					mensualidades pendientes.
				</p>
				<p className={style.textAlign_Center}>
					Las condiciones de los productos de reestructura existentes en el
					Infonavit tienen como objetivo apoyar a las personas acreditadas en la regularización de su
					crédito y en algunos casos en la disminución de pago de su mensualidad, dependiendo de
					las condiciones del crédito y de la situación actual de ingresos.
				</p>
				<p className={style.textAlign_Center}>
					Algunos de estos apoyos de reestructura pueden ser temporales o permanentes a partir de la
					formalización de un convenio, sujeta a revisión en caso de incumplimiento de acuerdo a lo
					establecido por la Comisión Nacional Bancaria y de Valores (CNBV), y estos apoyos de reestructura
					se pueden cancelar en cualquier momento si la persona acreditada deja de pagar a partir de un periodo de 90 días.
				</p>
				<Box className={style.BoxAlert}>
					<CustomAlert
						message={alertGral?.message}
						severity={alertGral?.severity}
						show={alertGral?.show}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '30px',
						marginBottom: '50px',
					}}
				>
					<Box className={style.Box_Buttons}>
						<Box className={style.BoxButton}>
							<CustomButton
								onClick={() => navigate('/inicio')}
								variant="outlined"
								label="Salir"
								styles={{ height: '100%' }}
							/>
						</Box>
						<Box className={style.BoxButton}>
							<CustomButton
								onClick={onNextPage}
								variant="solid"
								label="Consultar"
								disabled={!isSuceptible}
							/>
						</Box>
					</Box>
				</Box>
			</div>
		</>
	);
};

export default Home;
