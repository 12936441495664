import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    mainInfo: {
        padding: 30,
        paddingBottom: 0,
        fontSize: '20px',
        fontWeight: 'bold',
    }
}));

export const centeredItems = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};